import React, { useEffect } from "react";
import useStyles from "../style";
import { renderErrorCreatePool } from "../../../utils/validate";
import { Controller } from "react-hook-form";
import { Select, MenuItem } from "@material-ui/core";

function PoolRelationship(props: any) {
  const classes = useStyles();
  const {
    register,
    errors,
    poolDetail,
    control,
    setValue,
    isEditDisabled,
    isDeployed,
  } = props;
  const renderError = renderErrorCreatePool;

  useEffect(() => {
    if (poolDetail && poolDetail.relationship_type) {
      setValue("relationship_type", poolDetail.relationship_type);
    }
  }, [poolDetail]);

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Relationship Type</label>
        <Controller
          control={control}
          defaultValue="None"
          className={classes.formControlInput}
          name="relationship_type"
          as={
            <Select
              labelId="relationship_type"
              id="relationship_type"
              name="relationship_type"
              disabled={isEditDisabled}
              style={{
                backgroundColor: isEditDisabled ? "#F0F0F0" : "transparent",
              }}
              // disabled={isDeployed}
            >
              <MenuItem
                disabled={
                  isDeployed &&
                  (poolDetail.relationship_type === "Giveaway" ||
                    poolDetail.relationship_type ===
                      "Giveaway (Labs Incubation)")
                }
                value="None"
              >
                None
              </MenuItem>
              {/* <MenuItem value="Incubation">Incubation</MenuItem> */}
              <MenuItem
                disabled={
                  isDeployed &&
                  (poolDetail.relationship_type === "Giveaway" ||
                    poolDetail.relationship_type ===
                      "Giveaway (Labs Incubation)")
                }
                value="Standard IDO"
              >
                Standard IDO
              </MenuItem>
              <MenuItem
                disabled={
                  isDeployed &&
                  (poolDetail.relationship_type === "Giveaway" ||
                    poolDetail.relationship_type ===
                      "Giveaway (Labs Incubation)")
                }
                value="Flash Sale"
              >
                Flash Sale{" "}
              </MenuItem>
              <MenuItem
                disabled={
                  isDeployed &&
                  poolDetail.relationship_type !== "Giveaway" &&
                  poolDetail.relationship_type !== "Giveaway (Labs Incubation)"
                }
                value="Giveaway"
              >
                Giveaway
              </MenuItem>
              {/* <MenuItem value="Exclusive IDO">Exclusive IDO</MenuItem> */}
              <MenuItem
                disabled={
                  isDeployed &&
                  (poolDetail.relationship_type === "Giveaway" ||
                    poolDetail.relationship_type ===
                      "Giveaway (Labs Incubation)")
                }
                value="Private Sale"
              >
                Private Sale
              </MenuItem>
              <MenuItem
                disabled={
                  isDeployed &&
                  (poolDetail.relationship_type === "Giveaway" ||
                    poolDetail.relationship_type ===
                      "Giveaway (Labs Incubation)")
                }
                value="Acceleration"
              >
                Acceleration
              </MenuItem>
              {/* <MenuItem value="Standard IDO">Standard IDO</MenuItem> */}
              <MenuItem
                disabled={
                  isDeployed &&
                  (poolDetail.relationship_type === "Giveaway" ||
                    poolDetail.relationship_type ===
                      "Giveaway (Labs Incubation)")
                }
                value="Diamond IDO"
              >
                Diamond IDO
              </MenuItem>
              <MenuItem
                disabled={
                  isDeployed &&
                  (poolDetail.relationship_type === "Giveaway" ||
                    poolDetail.relationship_type ===
                      "Giveaway (Labs Incubation)")
                }
                value="Flash Sale (Incubation)"
              >
                Flash Sale (Incubation)
              </MenuItem>
              <MenuItem
                disabled={
                  isDeployed &&
                  (poolDetail.relationship_type === "Giveaway" ||
                    poolDetail.relationship_type ===
                      "Giveaway (Labs Incubation)")
                }
                value="IDO (Labs Incubation)"
              >
                IDO (Labs Incubation)
              </MenuItem>
              <MenuItem
                disabled={
                  isDeployed &&
                  poolDetail.relationship_type !== "Giveaway" &&
                  poolDetail.relationship_type !== "Giveaway (Labs Incubation)"
                }
                value="Giveaway (Labs Incubation)"
              >
                Giveaway (Labs Incubation)
              </MenuItem>
              <MenuItem
                disabled={
                  isDeployed &&
                  (poolDetail.relationship_type === "Giveaway" ||
                    poolDetail.relationship_type ===
                      "Giveaway (Labs Incubation)")
                }
                value="Seed (Labs Incubation)"
              >
                Seed (Labs Incubation)
              </MenuItem>
              <MenuItem
                disabled={
                  isDeployed &&
                  (poolDetail.relationship_type === "Giveaway" ||
                    poolDetail.relationship_type ===
                      "Giveaway (Labs Incubation)")
                }
                value="Private (Labs Incubation)"
              >
                Private (Labs Incubation)
              </MenuItem>
            </Select>
          }
        />
        <p className={classes.formErrorMessage}>
          {renderError(errors, "relationship_type")}
        </p>
      </div>
    </>
  );
}

export default PoolRelationship;
