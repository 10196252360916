const CLAIM_TOKEN_LOADING = 'CLAIM_TOKEN_LOADING';
const CLAIM_TOKEN_SUCCESS = 'CLAIM_TOKEN_SUCCESS';
const CLAIM_TOKEN_FAILURE = 'CLAIM_TOKEN_FAILURE';

const CLAIM_TOKEN_AVAILABLE_LOADING = 'CLAIM_TOKEN_AVAILABLE_LOADING';
const CLAIM_TOKEN_AVAILABLE_SUCCESS = 'CLAIM_TOKEN_AVAILABLE_SUCCESS';
const CLAIM_TOKEN_AVAILABLE_FAILURE = 'CLAIM_TOKEN_AVAILABLE_FAILURE';

const CLAIM_TOKEN_STAKED_LOADING = 'CLAIM_TOKEN_STAKED_LOADING'; 
const CLAIM_TOKEN_STAKED_SUCCESS = 'CLAIM_TOKEN_STAKED_SUCCESS';
const CLAIM_TOKEN_STAKED_FAILURE = 'CLAIM_TOKEN_STAKED_FAILURE';

export const claimTokenActions = {
  CLAIM_TOKEN_LOADING,
  CLAIM_TOKEN_SUCCESS,
  CLAIM_TOKEN_FAILURE,
  CLAIM_TOKEN_AVAILABLE_LOADING,
  CLAIM_TOKEN_AVAILABLE_SUCCESS,
  CLAIM_TOKEN_AVAILABLE_FAILURE,
  CLAIM_TOKEN_STAKED_LOADING,
  CLAIM_TOKEN_STAKED_SUCCESS,
  CLAIM_TOKEN_STAKED_FAILURE
};
