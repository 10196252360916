import { PublicKey } from "@solana/web3.js";

const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new PublicKey(process.env.REACT_APP_SOLANA_LIB_SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID || "")
const BLANK_ADDRESS = new PublicKey(process.env.REACT_APP_SOLANA_LIB_BLANK_ADDRESS || "");
const PROGRAM_ID = new PublicKey(
    process.env.REACT_APP_SOLANA_LIB_PROGRAM_ID || ""
)
const TOKEN_PROGRAM_ID = new PublicKey(process.env.REACT_APP_SOLANA_LIB_TOKEN_PROGRAM_ID || "");
export {
    TOKEN_PROGRAM_ID,
    SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
    BLANK_ADDRESS,
    PROGRAM_ID
};