import React, { useEffect, useState, useRef } from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  addBlogDetails,
  getBlog,
  getBlogById,
  getFeeds,
  updateBlog,
} from "../../request/blog";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { parseString } from "xml2js";
import SelectSearch from "react-select";
import { useCommonStyle } from "../../styles";
import { useDebounce } from "use-debounce";
import { getCampaigns } from "../../store/actions/campaign";
import { convertDateTimeToUnix } from "../../utils/convertDate";
import useStyles from "../../pages/Pools/style";
import blogStyles from "./style";
import { alertFailure, alertSuccess } from "../../store/actions/alert";

const BlogForm = () => {
  const history = useHistory();
  const [blog, setBlog] = useState("");
  const [category, setCategory] = useState("");
  const [pool, setPool] = useState("");
  const [poolId, setPoolId] = useState<number>(-1);
  const [feedItems, setFeedItems] = useState<any>([]);
  const [isActive, setIsActive] = useState(true);
  const [query, setQuery] = useState("");
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [finishTime, setFinishTime] = useState<Date | null>(null);
  const [filter, setFilter] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const {
    page = 1,
    lastPage,
    data: campaigns,
  } = useSelector((state: any) => state.campaigns.data);
  const [currentPage, setCurrentPage] = useState(page);
  // @ts-ignore
  let { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = blogStyles();
  const commonStyle = useCommonStyle();
  const inputRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  let label = "title";

  function toggle(e: any) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const handleCampaignQuery: any = (
    currentPage: number,
    query: string,
    startTime: Date | null,
    finishTime: Date | null,
    filter: boolean,
    isDisplay: boolean | number
  ) => {
    dispatch(
      getCampaigns(
        currentPage,
        query,
        convertDateTimeToUnix(startTime),
        convertDateTimeToUnix(finishTime),
        filter,
        (isDisplay = true)
      )
    );
  };

  useEffect(() => {
    // if(ACTIVE_STATUS) {
    handleCampaignQuery(
      currentPage,
      query,
      startTime,
      finishTime,
      filter,
      true
    );
    // } else {
    //   handleCampaignQuery(currentPage, query, startTime, finishTime, filter, ACTIVE_STATUS);
    // }
  }, [dispatch, currentPage, query, startTime, finishTime, filter]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await getFeeds();
        setFeedItems(response?.data?.rss?.channel[0].item);
      } catch (error) {
        console.error("Error fetching RSS feed:", error);
      }
    };

    fetchBlogs();
  }, []);

  const handleBlogChange = (event: any) => {
    setBlog(event.target.value);
  };

  const handleCategoryChange = (event: any) => {
    setCategory(event.target.value);
  };

  const handlePoolChange = (value: any) => {
    console.log(value, "valueeee");
    setPool(value);
  };

  const fetchData = async (id: number) => {
    try {
      const response = await getBlogById(id);
      console.log(response, "response by id");
      if (response?.status === 200) {
        setBlog(response?.data?.blog_title);
        setPool(response?.data?.title);
        setCategory(response?.data?.category);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let response;
    try {
      if (id) {
        const blogdata = {
          blogTitle: blog,
          campaignId: poolId,
          category: category,
        };
        response = await updateBlog(blogdata, id);
        if (response.status == 200) {
          dispatch(alertSuccess("Blog updated successful!"));
        } else {
          dispatch(alertFailure("Blog updated Fail!"));
        }
      } else {
        response = await addBlogDetails(blog, poolId, category);
        if (response.status == 200) {
          dispatch(alertSuccess("New Blog created!"));
        } else {
          dispatch(alertFailure("New Blog Fail!"));
        }
      }

      if (response?.status === 200) {
        history.push("/dashboard/blog");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCampaignSearch = (event: any) => {
    setCurrentPage(1);
    setQuery(event.target.value);
    handlePoolChange(null);
  };

  const getDisplayValue = () => {
    if (query) return query;
    if (pool) return pool;

    return "";
  };

  const selectOption = (option: any) => {
    setQuery(() => "");
    handlePoolChange(option[label]);
    setPoolId(option.id);
    setIsOpen((isOpen) => !isOpen);
  };

  const filterOptions = (options: any) => {
    return options?.filter(
      (option: any) =>
        option[label]?.toLowerCase().indexOf(query?.toLowerCase()) > -1
    );
  };

  const [delayCampaignSearch] = useDebounce(handleCampaignSearch, 500);
  return (
    <DefaultLayout>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div style={{ display: "flex", width: "100%" }}>
          <div className={styles.dropdown}>
            <div className={styles.selectedValueInput}>
              <input
                className={commonStyle.inputSearch}
                onChange={delayCampaignSearch}
                placeholder="Search Pool"
                value={getDisplayValue()}
                onClick={toggle}
                ref={inputRef}
              />
              <div className={`${styles.arrow} ${isOpen ? "open" : ""}`}></div>
            </div>
            <div className={`${styles.options} ${isOpen ? "open" : ""}`}>
              {filterOptions(campaigns)?.length > 0 ? (
                filterOptions(campaigns).map((option: any, index: any) => (
                  <div
                    onClick={() => selectOption(option)}
                    className={`${styles.option} ${
                      option[label] === pool ? "selected" : ""
                    }`}
                    key={`${id}-${index}`}
                  >
                    {option[label]}
                  </div>
                ))
              ) : (
                <div className={styles.option} style={{ textAlign: "center" }}>
                  No data found
                </div>
              )}
            </div>
          </div>
          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            style={{ marginLeft: "10px" }}
          >
            <InputLabel id="blog-label">Blog</InputLabel>
            <Select
              labelId="blog-label"
              id="blog"
              label="Blog"
              onChange={handleBlogChange}
              value={blog}
            >
              <MenuItem value=" " disabled>
                Select Blog
              </MenuItem>
              {feedItems?.map((feed: any, index: any) => (
                <MenuItem value={feed?.title[0]}>{feed.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            style={{ marginLeft: "10px" }}
          >
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              id="category"
              label="Category"
              onChange={handleCategoryChange}
              value={category}
            >
              <MenuItem value=" " disabled>
                Select Category
              </MenuItem>
              <MenuItem value="None">None</MenuItem>
              <MenuItem value="Airdrop">Airdrop</MenuItem>
              <MenuItem value="Blockchain">Blockchain</MenuItem>
              <MenuItem value="Web3">Web3</MenuItem>
              <MenuItem value="AI">AI</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: "10px" }}
          onClick={() => {
            history.push("/dashboard/blog");
          }}
        >
          Back
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </DefaultLayout>
  );
};

export default BlogForm;
