import React, { useEffect, useState } from "react";
import useStyles from "../../style";
import { NETWORK_AVAILABLE } from "../../../../constants";
import SolanaTokenDepositForm from "./SolanaTokenDepositForm";
import { renderErrorCreatePool } from "../../../../utils/validate";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../../../store/actions/alert";
import { Button } from "@material-ui/core";
import useSolanaRefundBalance from "../hooks/useSolanaRefundbalance";
import {
  apiRoute,
  convertToTransaction,
  deriveTokenAccount,
  SendSolanaTransaction,
} from "../../../../utils";
import { BaseRequest } from "../../../../request/Request";

function SolanaDepositRefundBalance(props: any) {
  const classes = useStyles();
  const { poolDetail, permission } = props;

  const [acceptCurrency, setAcceptCurrency] = useState<any>();
  const [tokenSymbol, setTokenSymbol] = useState<any>();
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const [amount, setAmount] = useState<any>("");
  const renderError = renderErrorCreatePool;
  const [disableButton, setDisableButton] = useState<Boolean>(false);
  const dispatch = useDispatch();

  const isDeployed = !!poolDetail?.is_deploy;

  const {
    refundBalance,
    contractBalance,
    rawDepositBalance,
    depositBalance,
    currencyAddress,
    totalRefundToken,
    getRefundBalance,
  } = useSolanaRefundBalance({ poolDetail });

  useEffect(() => {
    if (poolDetail) {
      getRefundBalance();
      setAcceptCurrency(
        poolDetail.accept_currency == NETWORK_AVAILABLE.ETH
          ? poolDetail.network_available
          : poolDetail.accept_currency
      );
      setTokenSymbol(poolDetail.symbol);
    }
  }, [poolDetail]);

  if (!isDeployed) return <></>;
  const handleCreateUpdateData = async () => {
    if (!window?.solana?.publicKey) {
      dispatch(alertFailure(`Please Connect your Solana Wallet`));
      return;
    }
    if (!amount || amount === "") {
      dispatch(alertFailure(`Please Enter Amount`));
      return;
    }
    // eslint-disable-next-line no-restricted-globals
    else if (!confirm(`Do you want to deposit money?`)) {
      return false;
    }
    try {
      setDisableButton(true);

      try {
        const USDT = process.env.REACT_APP_SOLANA_USDT_ADDRESS;
        const USDC = process.env.REACT_APP_SOLANA_USDC_ADDRESS;
        const buyTokenMint: any =
          poolDetail?.accept_currency === "usdt" ? USDT : USDC;
        const tokenAddress = deriveTokenAccount(
          window?.solana?.publicKey.toString(),
          buyTokenMint
        ).toBase58();
        const baseRequest = new BaseRequest();
        let url = apiRoute(`/solana/transfer-tokens`);
        const body = {
          source_token_account: tokenAddress,
          dest_token_account: poolDetail.refundable_token_account,
          amount: amount,
          decimals: 6,
          fee_payer: window?.solana?.publicKey.toString(),
        };
        const response = (await baseRequest.post(url, body)) as any;
        const data = await response.json();
        const tx = await convertToTransaction(data);
        const sig = await SendSolanaTransaction(tx);
        console.log("🚀 ~ handleRegisterPool ~ sig:", sig);
        dispatch(
          alertSuccess(
            `Payment Added Successfully !!! Please check this transaction`
          )
        );
        setDisableButton(false);
        setIsOpenEditPopup(false);
      } catch (err: any) {
        dispatch(alertFailure(err?.message));
        setDisableButton(false);
        setIsOpenEditPopup(false);
      }
    } catch (e) {
      setDisableButton(false);
      dispatch(alertFailure("Deposit Fail !!!"));
      setIsOpenEditPopup(false);
      return false;
    }
  };

  const openPopupCreate = (e: any) => {
    setIsOpenEditPopup(true);
  };

  return (
    <>
      <div className={classes.formControl}>
        <br />
        <label className={classes.exchangeRateTitle}>Refund Tokens</label>
        <br />
        <br />

        <label className={classes.formControlLabel}>
          Refunding Amount: {refundBalance} {acceptCurrency}
        </label>
        <br />
        <label className={classes.formControlLabel}>
          Contract Balance: {contractBalance} {acceptCurrency}
        </label>
        <br />
        <label className={classes.formControlLabel}>
          Deposit Amount: {depositBalance} {acceptCurrency}
        </label>
        <br />
        <label className={classes.formControlLabel}>
          Total Refunding Token: {totalRefundToken} {acceptCurrency}
        </label>
        <br />
        <br />
        {isOpenEditPopup && (
          <SolanaTokenDepositForm
            isOpenEditPopup={isOpenEditPopup}
            setIsOpenEditPopup={setIsOpenEditPopup}
            renderError={renderError}
            setAmount={setAmount}
            amount={amount}
            handleCreateUpdateData={handleCreateUpdateData}
            disableButton={disableButton}
            acceptCurrency={acceptCurrency}
          />
        )}
        <div className={`${classes.formControl} ${classes.flexRow}`}>
          <Button
            disabled={
              !rawDepositBalance || +rawDepositBalance == 0 || !permission
            }
            variant="contained"
            color="primary"
            onClick={openPopupCreate}
          >
            Deposit Refund Amount
          </Button>
        </div>
        <></>
      </div>
    </>
  );
}

export default SolanaDepositRefundBalance;
