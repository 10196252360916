import { useCallback, useState } from "react";
import BigNumber from "bignumber.js";
import { ACCEPT_CURRENCY } from "../../../../constants";
import { PublicKey } from "@solana/web3.js";
import { getTokenInfo } from "../../../../utils";

const useGetSolanaRefundBalance = (props: any) => {
  const { poolDetail } = props;
  const [currencyAddress, setCurrencyAddress] = useState<any>();
  const [rawContractBalance, setRawContractBalance] = useState<any>();


  const getRefundBalance = useCallback(async () => {
    try {
      const account = await getTokenInfo(
        new PublicKey(poolDetail?.refundable_token_account),
      );
      const currencyAddress =
        poolDetail.accept_currency === ACCEPT_CURRENCY.USDT
          ? process.env.REACT_APP_SOLANA_USDT_ADDRESS
          : process.env.REACT_APP_SOLANA_USDC_ADDRESS;

      setCurrencyAddress(currencyAddress);
      const rawContractBalance = new BigNumber(account.amount.toString())
        .div(new BigNumber(10).pow(6))
        .toString();
      setRawContractBalance(rawContractBalance);
    } catch (error: any) {
      console.log("error: ", error.message);
    }
  }, [poolDetail]);

  return {
    rawContractBalance,
    currencyAddress,
    getRefundBalance,
  };
};

export default useGetSolanaRefundBalance;
