import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  dropdown: {
    position: "relative",
    color: "#333",
    cursor: "default",
    marginTop: "15px",
    "&.arrow": {
      borderColor: "#999 transparent transparent",
      borderStyle: "solid",
      borderWidth: "5px 5px 0",
      content: '""', // This is used only in pseudo-elements in CSS, not directly applicable here
      display: "block",
      height: 0,
      marginTop: theme.spacing(0.5),
      position: "absolute",
      right: theme.spacing(1.25),
      top: theme.spacing(2),
      width: 0,

      "&.open": {
        borderColor: "transparent transparent #999",
        borderWidth: "0 5px 5px",
      },
    },
  },
  arrow: {
    borderColor: "#999 transparent transparent",
    borderStyle: "solid",
    borderWidth: "5px 5px 0",
    content: '""', // This is used only in pseudo-elements in CSS, not directly applicable here
    display: "block",
    height: 0,
    marginTop: theme.spacing(0.5),
    position: "absolute",
    right: theme.spacing(1.25),
    top: theme.spacing(2),
    width: 0,

    "&.open": {
      borderColor: "transparent transparent #999",
      borderWidth: "0 5px 5px",
    },
  },
  selectedValueInput: {
    lineHeight: 1.5,
    fontSize: "1rem",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: theme.shape.borderRadius,
    boxSizing: "border-box",
    cursor: "default",
    outline: "none",
    padding: theme.spacing(1, 6.5, 1, 1.25),
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    width: "100%",
  },
  options: {
    display: "none",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    boxShadow: theme.shadows[1],
    boxSizing: "border-box",
    marginTop: -1,
    maxHeight: 200,
    overflowY: "auto",
    position: "absolute",
    top: "100%",
    width: "100%",
    zIndex: 1000,
    WebkitOverflowScrolling: "touch",

    "&.open": {
      display: "block",
    },
  },
  option: {
    boxSizing: "border-box",
    color: "rgba(51, 51, 51, 0.8)",
    cursor: "pointer",
    display: "block",
    padding: theme.spacing(1, 1.25),

    "&.selected, &:hover": {
      backgroundColor: "#f2f9fc",
      color: "#333",
    },
  },
}));

export default useStyles;
