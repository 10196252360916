import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import CustomButton from "./Button";
import InputField from "./TextField";
import useStyles from "./style";
import CustomAccordion from "./CustomAccordion";
import { apiRoute, convertToTransaction, SendSolanaTransaction } from "../../../utils";
import { BaseRequest } from "../../../request/Request";

export default function LamportData({
  vaultAddress,
  pool_index,
  poolId,
  multiSigWallet,
  isValidVault,
  owner,
  disableButton
}: any) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [rate, setRate] = useState(0);
  const [loading, setLoading] = useState<any>(false);
  const [decimals, setDecimals] = useState<number>(0);
  const [fundingAccount, setFundingAccount] = useState("");
  const [errors, setErrors] = useState<any>({});

  const handleRateChange = (e: any) => {
    setRate(e.target.value);
    if (rate) {
      setErrors((prevErrors : any) => {
        const { rate, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  };

  const handleDecimalChange = (e: any) => {
    setDecimals(e.target.value);
    if (decimals) {
      setErrors((prevErrors : any) => {
        const { decimals, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  };

  const handleFundingAccountChange = (e: any) => {
    setFundingAccount(e.target.value);
    if (fundingAccount) {
      setErrors((prevErrors : any) => {
        const { fundingAccount, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  };

  useEffect(() => {
    if(rate) {
      setErrors((prevErrors : any) => {
        const { rate, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [rate])

  useEffect(() => {
    if(rate > 0) {
      setErrors((prevErrors : any) => {
        const { rate, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [rate])

  useEffect(() => {
    if(decimals) {
      setErrors((prevErrors : any) => {
        const { decimals, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [decimals])
  useEffect(() => {
    if(fundingAccount) {
      setErrors((prevErrors : any) => {
        const { fundingAccount, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [fundingAccount])

  const setOfferedLamportsData = async () => {
    if(!window?.solana?.publicKey) {
      dispatch(alertFailure("Please Connect Solana Wallet"))
      return;
    }
    if(!pool_index) {
      dispatch(alertFailure("Pool is not deployed"))
      return;
    }
    if(!multiSigWallet) {
      dispatch(alertFailure("Please Enter MultiSig Wallet Address"))
      return;
    }
    if(!vaultAddress) {
      dispatch(alertFailure("Please Enter Vault Address"))
      return;
    }
    if(owner && !isValidVault) {
      dispatch(alertFailure("Vault Address should be the owner of Pool"));
      return;
    }
    const errors: any = {};
    if (!rate) {
      errors.rate = "Rate is required";
    }
    if (rate <= 0) {
      errors.rate = "Rate must be greater than zero";
    }
    if (!decimals) {
      errors.decimals = "Decimals is required";
    }
    if (!fundingAccount) {
      errors.fundingAccount = "Funding Account is required";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true)
      try {
        const squads_multi_sig_address = multiSigWallet;
        const baseRequest = new BaseRequest();
        let url = apiRoute(`/solana-multisig/set-offered-lamport-data`);
        const body = {
          squads_multi_sig_address: squads_multi_sig_address, 
          campaign_id: poolId, 
          rate: rate,
          decimals,
          funding_account: fundingAccount,
          fee_payer: window?.solana?.publicKey.toString()
      }
        const response = (await baseRequest.post(url, body)) as any;
        const result = await response.json();
        const data = result.data;
        const tx = await convertToTransaction(data)
        const sig = await SendSolanaTransaction(tx);
        dispatch(alertSuccess("Transaction Successfull"));
        setRate(0);
        setDecimals(0);
        setFundingAccount("");
        setLoading(false);
      } catch (error) {
        dispatch(alertFailure("Transaction Failed"));
        setRate(0);
        setDecimals(0);
        setFundingAccount("");
        setLoading(false);
      }
    } else {
      setErrors(errors);
    }
  };
  return (
    <div className={classes.spaceAbove}>
      <CustomAccordion title="Set Offered Lamports Data">
        <div className={classes.columnFlex}>
          <InputField
            value={rate}
            onChange={handleRateChange}
            error={errors.rate}
            label="Enter Rate"
            id="rate"
            disableButton = {disableButton}
          />
          <InputField
            value={decimals}
            onChange={handleDecimalChange}
            error={errors.decimals}
            label="Enter Decimals"
            id="decimals"
            disableButton = {disableButton}
          />
          <InputField
            value={fundingAccount}
            onChange={handleFundingAccountChange}
            error={errors.fundingAccount}
            label="Enter Funding Account"
            id="fundingAccount"
            disableButton = {disableButton}
          />
          <CustomButton disableButton = {disableButton} loading = {loading} onClick={() => setOfferedLamportsData()} />
        </div>
      </CustomAccordion>
    </div>
  );
}
