import { Button, TextField } from "@material-ui/core";
import React from "react";
import useStyles from "./style";

export default function VaultAddress({
  setIsEditVaultWallet,
  vaultWallet,
  errors,
  handleVaultAddressChange,
  isEditVaultWallet,
  disableButton
}: any) {
  const classes = useStyles();
  return (
    <div>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.rightAlign}
        onClick={() => setIsEditVaultWallet(true)}
        disabled = {disableButton}
      >
        Change Address
      </Button>
      <TextField
        label="Vault Address"
        type="text"
        id="vaultaddress"
        value={vaultWallet}
        onChange={handleVaultAddressChange}
        variant="outlined"
        margin="normal"
        fullWidth
        error={!!errors.vaultWallet}
        disabled={!isEditVaultWallet || disableButton}
      />
      {errors.vaultWallet && (
        <div className={classes.error}>{errors.vaultWallet}</div>
      )}
    </div>
  );
}
