import React from "react";
import ConfirmDialog from "../../../components/Base/ConfirmDialog";

const AdminDeleteRowForm = (props: any) => {
  const {
    isOpenEditPopup,
    setIsOpenEditPopup,
    onConfirmDelete,
    disableButton,
  } = props;

  const handleSubmitPopup = () => {
    onConfirmDelete();
  };
  return (
    <ConfirmDialog
      title={"Delete"}
      open={isOpenEditPopup}
      confirmLoading={disableButton}
      onConfirm={handleSubmitPopup}
      onCancel={() => {
        setIsOpenEditPopup(false);
      }}
    >
      <div>Are you sure you want to delete this user?</div>
    </ConfirmDialog>
  );
};

export default AdminDeleteRowForm;
