import { FormControl } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field';
import useStyles from '../style';

const InitialMarketCapitilization = (props: any) => {
  const classes = useStyles();
  const { register, setValue, errors, poolDetail, control, watch, isEditDisabled } = props;
  const [initialCapitalization, setInitialCapitalization] = useState("");


  useEffect(() => {
    if (poolDetail && poolDetail.imc_excl_liq) {
        console.log(poolDetail?.imc_excl_liq)
      setInitialCapitalization(poolDetail.imc_excl_liq);
    }
  }, [poolDetail, poolDetail?.token]);

  const handleChange = (value: any, name: any) => {
    setInitialCapitalization(value);
    setValue("initialCapitalization", value);
  };

  return (
    <>
      <br />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FormControl component="fieldset">
          <label className={classes.formControlLabel}>
            Initial Market Capitilization
          </label>
          <CurrencyInput
            id="totalSoldCoin"
            placeholder="Please enter value"
            value={initialCapitalization}
            decimalsLimit={2}
            onValueChange={handleChange}
            className={`${classes.formInputBox}`}
            disabled={isEditDisabled}
            style = {{
              backgroundColor: isEditDisabled ? "#F0F0F0" : "transparent",
            }}
          />
          <input
            type="hidden"
            name="initialCapitalization"
            value={initialCapitalization || ""}
            ref={register({})}
          />
        </FormControl>
      </div>
    </>
  );
}

export default InitialMarketCapitilization