import React from "react";
import MenuLinks from "./MenuLinks";
import Breadcrumb from "../../components/Base/BreadCrumbs";
import DefaultLayout from "../../components/Layout/DefaultLayout";

const Menu = () => {
  return (
    <DefaultLayout>
      <div className="page-content">
        <Breadcrumb title="Menu" breadcrumbItem="Menu" />
        <MenuLinks />
      </div>
    </DefaultLayout>
  );
};

export default Menu;
