import React, { useEffect } from "react";
import {
  TableRow,
  TableCell,
  DialogContent,
  Tooltip,
  Popper,
} from "@material-ui/core";
import useComponentVisible from "../../../hooks/useComponentVisible";
import { Link } from "react-router-dom";
import moment from "moment";

import useStyles from "./style";
import { adminRoute } from "../../../utils";

type PoolProps = {
  id: string;
  title: string;
  token: string;
  start_time: number;
  finish_time: number;
  affiliate: number;
  tokenGet: number;
  ethFor: number;
  campaign_hash: string;
  symbol: string;
  is_pause: number;
  is_display: number;
  blockchain_status: number;
  network_available?: string;
};

type PoolRecordProps = {
  campaign: PoolProps;
  currentOpen: string;
  setCurrentOpen: (id: string) => void;
};

const SolanaRecord: React.FC<PoolRecordProps> = (props: PoolRecordProps) => {
  const { campaign, currentOpen, setCurrentOpen } = props;
  const classes = useStyles();

  const { ref, isVisible, setIsVisible } = useComponentVisible();

  useEffect(() => {
    currentOpen && setCurrentOpen("");
  }, [campaign]);

  useEffect(() => {
    setIsVisible(campaign.id === currentOpen);
  }, [currentOpen]);

  const getCampaignStatus = (campaign: PoolProps) => {
    switch (campaign.is_display) {
      case 0:
        return "InActive";
      case 1:
        return "Active";
    }
    return "";
  };

  return (
    <TableRow
      ref={ref}
      className={classes.tableRow}
      key={campaign.id}
      component={Link}
      to={adminRoute(`/solana-multi-sig/${campaign.id}`)}
    >
      <TableCell component="div" className={classes.tableCellTitle} scope="row">
        <Tooltip title={<p style={{ fontSize: 15 }}>{campaign.title}</p>}>
          <span className={classes.wordBreak}>{campaign.title}</span>
        </Tooltip>
      </TableCell>
      <TableCell component="div" className={classes.tableCell} align="left">
        <Tooltip
          title={
            <p style={{ fontSize: 15 }}>
              {campaign?.network_available?.toUpperCase()}
            </p>
          }
        >
          <span className={classes.wordBreak} style={{ width: 100 }}>
            {campaign?.network_available?.toUpperCase()}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell component="div" className={classes.tableCell} align="left">
        {moment.unix(campaign.start_time).format("hh:mm:ss A")}
        <br />
        {moment.unix(campaign.start_time).format("MM/DD/YYYY")}
      </TableCell>
      <TableCell component="div" className={classes.tableCell} align="left">
        {moment.unix(campaign.finish_time).format("hh:mm:ss A")}
        <br />
        {moment.unix(campaign.finish_time).format("MM/DD/YYYY")}
      </TableCell>

      <TableCell component="div" className={classes.tableCell} align="left">
        <Tooltip title={<p style={{ fontSize: 15 }}>{campaign.symbol}</p>}>
          <span className={classes.wordBreak} style={{ width: 100 }}>
            {campaign.symbol}
          </span>
        </Tooltip>
      </TableCell>

      <TableCell component="div" className={classes.tableCell} align="left">
        <div className={classes.tableCellFlex}>
          <div className="left">
            <Tooltip
              title={
                <p style={{ fontSize: 15 }}>{getCampaignStatus(campaign)}</p>
              }
            >
              <span
                className={`campaign-status campaign-${getCampaignStatus(
                  campaign
                ).toLowerCase()}`}
              ></span>
            </Tooltip>
            {getCampaignStatus(campaign)}
          </div>
          <div className="right">
            <img
              src="/images/icon_menu.svg"
              alt="icon-menu"
              onClick={(e) => {
                e.preventDefault();

                if (campaign.id === currentOpen && isVisible) {
                  setIsVisible(false);
                  setCurrentOpen("");
                  return;
                }

                setCurrentOpen(campaign.id);
                setIsVisible(true);
              }}
            />
            <Popper
              open={isVisible}
              disablePortal
              className={classes.editDialog}
            >
              <DialogContent className={classes.editDialogContent}>
                <Link
                  className={`${classes.editDialogView} dialog-cta`}
                  to={adminRoute(`/solana-multi-sig/${campaign.id}`)}
                >
                  View
                </Link>
              </DialogContent>
            </Popper>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default SolanaRecord;
