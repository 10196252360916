"use client";
import React from "react";
import Breadcrumb from "../../components/Base/BreadCrumbs";
import TermsOfServiceComponent from "./TermsOfServiceComponent";
import DefaultLayout from "../../components/Layout/DefaultLayout";

const TermsOfService = () => {
  return (
    <DefaultLayout>
      <div className="page-content">
        <Breadcrumb
          title="Terms of Service"
          breadcrumbItem="Terms of Service"
        />
        <TermsOfServiceComponent />
      </div>
    </DefaultLayout>
  );
};

export default TermsOfService;
