import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerRight: {
    display: "flex",
  },
  tableContainer: {
    padding: "30px 20px",
    borderRadius: 10,
    boxShadow: "none",
    marginTop: 25,
  },
  table: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 14,
    backgroundColor: "white",
  },
  tableHeader: {
    padding: "25px 30px",
    color: "#363636",
    fontWeight: "bold",
  },
  tableBody: {
    "& > .MuiTableRow-root:last-child": {
      borderBottom: "1px solid #E5E5E5",
    },
    "& > .MuiTableRow-root:nth-child(odd)": {
      backgroundColor: "white",
    },
  },
  pagination: {
    marginTop: 30,
    fontSize: 12,
    fontWeight: 400,
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#FFCC00",
    },
  },
  skeleton: {
    padding: "25px 0px",
    marginTop: 10,
  },
  noDataMessage: {
    fontWeight: 500,
    marginTop: 30,
    textAlign: "center",
    fontSize: 15,
  },
  errorMessage: {
    fontWeight: 500,
    marginTop: 30,
    textAlign: "center",
    fontSize: 15,
    color: "red",
  },
  refreshCampaigns: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    justifyContent: "space-between",
  },
  refreshCampaignsContainer: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",

    "&:hover .refreshCampaignsIcon": {
      transform: "rotateZ(180deg)",
      fill: "#FFCC00",
    },
  },
  refreshCampaignsText: {
    marginLeft: 10,
    fontWeight: 500,
    fontSize: 15,
    transition: ".2s all ease-in",
    fontFamily: "Roboto-Medium",
  },
  refreshCampaignsIcon: {
    transition: ".2s all ease-in",
  },

  tableRow: {
    backgroundColor: "#f3f3f3",
    borderRadius: 5,

    "& > .MuiTableCell-root:first-child": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },

    "& > .MuiTableCell-root:last-child": {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
  tableCell: {
    color: "#636363",
    fontSize: 14,
    fontWeight: 400,
    padding: "25px 30px",
    borderBottom: "none",

    "& .admin-status": {
      width: 10,
      height: 10,
      borderRadius: "50%",
      display: "inline-block",
      marginRight: 6,
    },

    "& .admin-paused": {
      backgroundColor: "red",
    },

    "& .admin-active": {
      backgroundColor: "#56b022",
    },

    "& .admin-suspend": {
      backgroundColor: "red",
    },

    "& .admin-processing": {
      backgroundColor: "#FFCC00",
    },
    "& .admin-inactive": {
      backgroundColor: "red",
    },
  },
  tableCellFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& .left": {
      display: "flex",
      alignItems: "center",

      "& > span": {
        marginLeft: 5,
      },
      "& > .check": {
        color: "#00AF12",
      },
      "& > .cancel": {
        color: "#B9B9B9",
      },
      "& > Button:disabled": {
        opacity: "0.6",
        cursor: "not-allowed",
        pointerEvents: "auto",
      },
    },

    "& .right": {
      cursor: "pointer",
      position: "relative",
    },
  },

  tableCellSpan: {
    transition: "all 1s ease",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  tableCellTitle: {
    fontSize: 14,
    color: "#3A39BB",
    padding: "25px 30px",
    borderBottom: "none",
    width: 275,
    fontWeight: "bold",
  },
  wordBreak: {
    display: "inline-block",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: 250,
    maxWidth: 250,
  },
  editDialog: {
    position: "absolute",
    right: 30,
    inset: "unset",
    bottom: -20,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: 10,
    backgroundColor: "white",

    "& .MuiDialogContent-root": {
      padding: "15px 25px",
      width: 130,
      textAlign: "center",
      color: "#636363",
    },

    "& .MuiDialog-paper": {
      borderRadius: 10,
      margin: 0,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
    },
  },
  editDialogContent: {
    "& hr": {
      marginTop: 15,
    },
  },
  editDialogView: {
    fontSize: 14,
    display: "inline-block",
    color: "#636363",
    transition: ".2s all ease-in",
    fontFamily: "Roboto, sans-serif",

    "&:hover": {
      color: "black",
    },
  },
  editDialogButton: {
    background: "none",
    border: "none",
    fontSize: 14,
    marginTop: 15,
    cursor: "pointer",
    color: "#636363",
    transition: ".2s all ease-in",
    display: "inline-block",

    "&:hover": {
      color: "black",
    },
  },
}));

export default useStyles;




// import { makeStyles } from "@material-ui/core";


// const useStyles = makeStyles((theme) => ({
//   header: {
//     display: "flex",
//     justifyContent: "space-between",
//   },
//   headerRight: {
//     display: "flex",
//   },
//   tableContainer: {
//     padding: "30px 20px",
//     borderRadius: 10,
//     boxShadow: "none",
//     marginTop: 25,
//   },
//   table: {
//     fontFamily: "Roboto",
//     fontWeight: 500,
//     fontSize: 14,
//     backgroundColor: "white",
//   },
//   tableHeader: {
//     padding: "25px 30px !important",
//     color: "#363636 !important",
//     fontWeight: "bold !important",
//   },
//   tableBody: {
//     "& > .MuiTableRow-root:last-child": {
//       borderBottom: "1px solid #E5E5E5",
//     },
//     "& > .MuiTableRow-root:nth-child(odd)": {
//       backgroundColor: "white",
//     },
//   },
//   pagination: {
//     marginTop: 30,
//     fontSize: 12,
//     fontWeight: 400,
//     "& .MuiPagination-ul": {
//       justifyContent: "center",
//     },
//     "& .MuiPaginationItem-page.Mui-selected": {
//       backgroundColor: "#FFCC00",
//     },
//   },
//   skeleton: {
//     padding: "25px 0px",
//     marginTop: 10,
//   },
//   noDataMessage: {
//     fontWeight: 500,
//     marginTop: 30,
//     textAlign: "center",
//     fontSize: 15,
//   },
//   errorMessage: {
//     fontWeight: 500,
//     marginTop: 30,
//     textAlign: "center",
//     fontSize: 15,
//     color: "red",
//   },
//   refreshCampaigns: {
//     display: "flex",
//     alignItems: "center",
//     marginTop: 20,
//     justifyContent: "space-between",
//   },
//   refreshCampaignsContainer: {
//     display: "flex",
//     cursor: "pointer",
//     alignItems: "center",

//     "&:hover .refreshCampaignsIcon": {
//       transform: "rotateZ(180deg)",
//       fill: "#FFCC00",
//     },
//   },
//   refreshCampaignsText: {
//     marginLeft: 10,
//     fontWeight: 500,
//     fontSize: 15,
//     transition: ".2s all ease-in",
//     fontFamily: "Roboto-Medium",
//   },
//   refreshCampaignsIcon: {
//     transition: ".2s all ease-in",
//   },

//   tableRow: {
//     backgroundColor: "#f3f3f3",
//     borderRadius: 5,

//     "& > .MuiTableCell-root:first-child": {
//       "border-top-left-radius": 5,
//       "border-bottom-left-radius": 5,
//     },

//     "& > .MuiTableCell-root:last-child": {
//       "border-top-right-radius": 5,
//       "border-bottom-right-radius": 5,
//     },
//   },
//   tableCell: {
//     color: "#636363 !important",
//     fontSize: 14,
//     fontWeight: 400,
//     padding: "25px 30px !important",
//     borderBottom: "none",

//     "& .admin-status": {
//       width: 10,
//       height: 10,
//       borderRadius: "50%",
//       display: "inline-block",
//       marginRight: 6,
//     },

//     "& .admin-paused": {
//       backgroundColor: "red",
//     },

//     "& .admin-active": {
//       backgroundColor: "#56b022",
//     },

//     "& .admin-suspend": {
//       backgroundColor: "red",
//     },

//     "& .admin-processing": {
//       backgroundColor: "#FFCC00",
//     },
//     "& .admin-inactive": {
//       backgroundColor: "red",
//     },
//   },
//   tableCellFlex: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",

//     "& .left": {
//       display: "flex",
//       alignItems: "center",

//       "& > span": {
//         marginLeft: 5,
//       },
//       "& > .check": {
//         color: "#00AF12",
//       },
//       "& > .cancel": {
//         color: "#B9B9B9",
//       },
//       "& > Button:disabled": {
//         opacity: "0.6",
//         cursor: "not-allowed",
//         pointerEvents: "auto",
//       },
//     },

//     "& .right": {
//       cursor: "pointer",
//       position: "relative",
//     },
//   },

//   tableCellSpan: {
//     transition: "all 1s ease !important",
//     "&:hover": {
//       textDecoration: "underline",
//     },
//   },
//   tableCellTitle: {
//     fontSize: 14,
//     color: "#3A39BB !important",
//     padding: "25px 30px !important",
//     borderBottom: "none",
//     width: 275,
//     fontWeight: "bold !important",
//   },
//   wordBreak: {
//     display: "inline-block",
//     whiteSpace: "nowrap",
//     textOverflow: "ellipsis",
//     overflow: "hidden",
//     width: 250,
//     maxWidth: 250,
//   },
//   editDialog: {
//     position: "absolute !important" as any,
//     right: "30px !important" as any,
//     inset: "unset !important" as any,
//     bottom: "-20px !important" as any,
//     boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
//     borderRadius: 10,
//     backgroundColor: "white",

//     "& .MuiDialogContent-root": {
//       padding: "15px 25px",
//       width: 130,
//       textAlign: "center",
//       color: "#636363",
//     },

//     "& .MuiDialog-paper": {
//       borderRadius: 10,
//       margin: 0,
//       boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
//     },
//   },
//   editDialogContent: {
//     "& hr": {
//       marginTop: 15,
//     },
//   },
//   editDialogView: {
//     fontSize: 14,
//     display: "inline-block",
//     color: "#636363",
//     transition: ".2s all ease-in",
//     fontFamily: "Roboto, sans-serif",

//     "&:hover": {
//       color: "black",
//     },
//   },
//   editDialogButton: {
//     background: "none",
//     border: "none",
//     fontSize: 14,
//     marginTop: 15,
//     cursor: "pointer",
//     color: "#636363",
//     transition: ".2s all ease-in",
//     display: "inline-block",

//     "&:hover": {
//       color: "black",
//     },
//   },
// }));

// export default useStyles;
