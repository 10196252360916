import React, { useEffect } from 'react';
import RightDefaultLayout from '../../Base/RightDefaultLayout';
import LeftDefaultLayout from '../../Base/LeftDefaultLayout';
import { useCommonStyle } from '../../../styles';
import { useLocation } from 'react-router';

const DefaultLayout = (props: any) => {
  const commonStyle = useCommonStyle();

  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = () => {
      if ((location.pathname !== "/dashboard/campaigns/solana/add" && location.pathname.startsWith("/dashboard/campaigns-detail/solana") && location.pathname.startsWith("/solana-mult-sig"))  && window?.solana?.isConnected) {
        window.solana.disconnect();
      }
    };
    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [location, window?.solana?.isConnected])

  return (
    <div className={commonStyle.DefaultLayout}>
      <LeftDefaultLayout />
      <RightDefaultLayout>{props.children}</RightDefaultLayout>
    </div>
  );
};

export default DefaultLayout;