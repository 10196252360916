import { BaseRequest } from "../Request";

interface GetRequestProps {
  url: string;
}

interface DELETERequestProps {
  url: string;
}

interface PostRequestProps {
  url: string;
  body: any;
}

interface PatchRequestProps {
  url: string;
  body: any;
}

export const fetchApiData = async ({ url }: GetRequestProps) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const deleteApiData = async ({ url }: DELETERequestProps) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};

export const deleteApiDataWithBody = async ({ url, body }: PostRequestProps) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.delete(url, body)) as any;
  return await response?.json();
};

export const saveApiData = async ({ url, body }: PostRequestProps) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const updateApiData = async ({ url, body }: PatchRequestProps) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.patch(url, body)) as any;
  return await response?.json();
};

export const updateData = async ({ url, body }: PatchRequestProps) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.put(url, body)) as any;
  return await response?.json();
};
