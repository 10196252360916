import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { setBonusPointUserCampaign } from '../../../../request/user';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import useStyles from './style';

function AddBonusForm(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    isOpen, setIsOpen, poolId, getParticipantUserWithTier
  } = props;

  const [bonusPoint, setBonusPoint] = useState<any>(0);
  const [wallet, setWallet] = useState("");

  const handleSubmitPopup = async () => {
    try {
      setIsOpen(false)

      const res = await setBonusPointUserCampaign({walletAddress: wallet, bonusPoint: Number(bonusPoint), poolId})
      if (res.status !== 200) {
        dispatch(alertFailure('Something wrong'))
        getParticipantUserWithTier(poolId)
        return
      }
      dispatch(alertSuccess('Updated bonus point'))
      getParticipantUserWithTier(poolId)
      setWallet("");
      setBonusPoint(0);

    } catch (err) {
      console.log(err)
      dispatch(alertFailure('Something wrong'))
      getParticipantUserWithTier(poolId)
    }
  };

  return (
    <>
      <ConfirmDialog
        title={'Bonus Point'}
        open={isOpen}
        confirmLoading={false}
        onConfirm={handleSubmitPopup}
        onCancel={() => { setIsOpen(false) }}
      >

        <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Wallet</label>
          <input
            value={wallet}
            onChange={e => setWallet(e.target.value)}
            className={classes.formControlInput}
          />
        </div>

        <div className={classes.formControl}>
          <label className={classes.formControlLabel}>Bonus Point</label>
          <input
            type="number"
            value={bonusPoint}
            onChange={e => setBonusPoint(e.target.value)}
            className={classes.formControlInput}
          />
        </div>

      </ConfirmDialog>

    </>
  );
}

export default AddBonusForm;
