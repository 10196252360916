import { FormControl } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import useStyles from "../style";

const TotalInitialMarketCapitilization = (props: any) => {
  const classes = useStyles();
  const { register, setValue, errors, poolDetail, control, watch, isEditDisabled } = props;
  const [totalinitialCapitalization, setTotalInitialCapitalization] =
    useState("");


  useEffect(() => {
    if (poolDetail && poolDetail.total_imc) {
      console.log(poolDetail?.total_imc);
      setTotalInitialCapitalization(poolDetail.total_imc);
    }
  }, [poolDetail, poolDetail?.token]);

  const handleChange = (value: any, name: any) => {
    setTotalInitialCapitalization(value);
    setValue("totalinitialCapitalization", value);
  };

  return (
    <>
      <br />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FormControl component="fieldset">
          <label className={classes.formControlLabel}>
            Total Initial Market Capitilization
          </label>
          <CurrencyInput
            id="totalSoldCoin"
            placeholder="Please enter value"
            value={totalinitialCapitalization}
            decimalsLimit={2}
            onValueChange={handleChange}
            className={`${classes.formInputBox}`}
            disabled={isEditDisabled}
            style = {{
              backgroundColor: isEditDisabled ? "#F0F0F0" : "transparent",
            }}
          />
          <input
            type="hidden"
            name="totalinitialCapitalization"
            value={totalinitialCapitalization || ""}
            ref={register({})}
          />
        </FormControl>
      </div>
    </>
  );
};

export default TotalInitialMarketCapitilization;
