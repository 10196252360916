import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import useStyles from "./style";
import {
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Switch,
  FormControl,
  Typography,
  TextField,
} from "@material-ui/core";
import {
  calculateTierJob,
  getAllFlags,
  getAllTier,
} from "../../request/tiers-range";
import { useHistory } from "react-router";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import { useDispatch } from "react-redux";
import RecalculateUserTiersForm from "./RecalculateUserTiersForm";
import EnableTiersForm from "./EnableTiersForm";
import { UseCheckPermissions } from "../../utils/useCheckPermissions";
import NoPermisisons from "../../components/Base/NoPermissions";
import { validateJson } from "../../utils";
import { getMaxCapValues, updatMaxCapValue } from "../../request/config";
import { MAX_CAP, TiersRangeListing } from "../../constants";

const TiersListing = () => {
  const [tiers, setTiers] = useState([]);
  const [tierFlag, setTierFlag] = useState(true);
  const [getFlag, setGetFlag] = useState<any>("");
  const [showHelperText, setShowHelperText] = useState(false);
  const [displayLabel, setDisplayLabel] = useState(true);
  const [isOpenEditPopup, setIsOpenEditPopup] = useState<boolean>(false);
  const [reCalculateTier, setRecalculateTier] = useState<boolean>(false);
  const [isOpenEditTierPopup, setIsOpenEditTierPopup] =
    useState<boolean>(false);
  const [localTierFlag, setLocalTierFlag] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [enableTierLoading, setEnableTierLoading] = useState(false);

  const confirmCalcualte = () => {
    setIsOpenEditPopup(true);
  };
  const fetchData = async () => {
    try {
      const response = await getAllTier();
      if (response?.status === 200) {
        setTiers(response?.data);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFlags = async () => {
    try {
      const response = await getAllFlags();
      if (response?.status === 200) {
        setGetFlag(response?.data[0]);
        setTierFlag(response.data[0].value);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTierJob = async () => {
    setLoading(true);
    setRecalculateTier(true);
    try {
      const response = await calculateTierJob();
      if (response?.status === 200) {
        dispatch(alertSuccess(response?.data || "Successfull"));
        setIsOpenEditPopup(false);
      } else {
        dispatch(alertFailure(response?.message || "Something went wrong"));
      }
      setLoading(false);
      setIsOpenEditPopup(false);
    } catch (error) {
      setLoading(false);
      setRecalculateTier(false);
      setIsOpenEditPopup(false);
    }
  };

  const handleSwitchChange = async (event: any) => {
    const { checked } = event?.target;
    const value: any = checked;
    setLocalTierFlag(value);
    setIsOpenEditTierPopup(true);
    setShowHelperText(!checked);
    setDisplayLabel(!checked);
  };

  useEffect(() => {
    fetchData();
    fetchFlags();
  }, []);

  const handleEditClick = (tier: any) => {
    history.push({
      pathname: `edit-tiers-range/${tier.id}`,
      state: {
        points: tier?.points,
        fcfs: tier?.fcfs_multiplier,
        tier_multiplier: tier?.tier_base_multiplier,
        tier: tier,
        tiers: tiers,
      },
    });
  };

  const tierName: any = {
    0: "-",
    1: "Bronze",
    2: "Silver",
    3: "Gold",
    4: "Diamond",
  };

  const isViewTierRange = UseCheckPermissions("VIEW_TIER_RANGES");
  const isEditTierRange = UseCheckPermissions("EDIT_TIER_RANGES");


  const [value, setValue] = useState<any>(null);
  const [error, setError] = useState("");
  const [id, setId] = useState(1);
  const [maxCapLoading, setMaxCapLoading] = useState(false);

  const fetchMaxCapData = async () => {
    const response = await getMaxCapValues();
    const data = response?.data[0];
    setValue(data.value?.value);
    setId(data.id);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    let error = "";
    event.preventDefault();
    if (value < 0) {
      error = `Max Cap value must be greater than zero`;
      setError(error);
      return;
    }
    setMaxCapLoading(true);
    let response;
    try {
      if (id) {
        response = await updatMaxCapValue(
          {
            value: { value},
          },
          id
        );
      }
      if (response?.status === 200) {
        dispatch(alertSuccess("Max Cap Updated Successfully"));
        setMaxCapLoading(false);
      }
    } catch (error) {
      setMaxCapLoading(false);
    }
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setValue(event.target.value);
  };

  useEffect(() => {
    if(MAX_CAP) {
      fetchMaxCapData();
    }
  }, []);
  return (
    <DefaultLayout>
      {isViewTierRange ? (
        <>
          {TiersRangeListing && <>
          <div>
            <RecalculateUserTiersForm
              isOpenEditPopup={isOpenEditPopup}
              setIsOpenEditPopup={setIsOpenEditPopup}
              onConfirmCalcualte={handleTierJob}
              loading={loading}
            />
            <EnableTiersForm
              isOpenEditTierPopup={isOpenEditTierPopup}
              setIsOpenEditTierPopup={setIsOpenEditTierPopup}
              onConfirmTiers={handleSwitchChange}
              getFlag={getFlag}
              tierFlag={tierFlag}
              setTierFlag={setTierFlag}
              displayLabel={displayLabel}
              localTierFlag={localTierFlag}
              setEnableTierLoading={setEnableTierLoading}
              enableTierLoading={enableTierLoading}
            />
            <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
              <Switch
                checked={tierFlag ? true : false}
                onChange={(e) => handleSwitchChange(e)}
                disabled={!isEditTierRange}
              />
              <h4 style={{ marginRight: "10px", marginTop: "5px" }}>
                Enable Tiers
              </h4>
              {showHelperText && (
                <Typography variant="body2" color="textSecondary">
                  (Turn on if you want to apply these tiers in existing system)
                </Typography>
              )}
              <div></div>
            </FormControl>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {/*<TableCell>Id</TableCell>*/}
                  <TableCell>Tier</TableCell>
                  <TableCell>Points</TableCell>
                  <TableCell>FCFS Multiplier</TableCell>
                  <TableCell>Guranted Multiplier</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tiers?.map((tier: any) => (
                  <TableRow key={tier.id}>
                    {/*<TableCell>{rpcUrl.id}</TableCell>*/}
                    <TableCell>{tierName[tier.tier]}</TableCell>
                    <TableCell>{tier.points}</TableCell>
                    <TableCell>{tier.fcfs_multiplier}x</TableCell>
                    <TableCell>{tier.tier_base_multiplier}x</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => handleEditClick(tier)}
                        disabled={!tierFlag || !isEditTierRange}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={confirmCalcualte}
            style={{ marginTop: "10px" }}
            disabled={
              !isEditTierRange ||
              loading ||
              !tierFlag
            }
          >
            Recalculate users tiers
          </Button>
          {showHelperText && (
            <Typography variant="body2" color="textSecondary">
              (Recalculate tiers of all existing users if tiers are updated)
            </Typography>
          )}
          </>}

          <h4 style={{ marginRight: "10px", marginTop: "25px" }}>Max Cap</h4>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <div>
              <TextField
                label="Max Cap Value"
                type="number"
                id="maxcap"
                variant="outlined"
                margin="normal"
                fullWidth
                value={value}
                defaultValue={value}
                onChange={handleValueChange}
                error={!!error}
                focused
              />
            </div>
            {error && <div style={{ color: "red" }}>{error}</div>}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={maxCapLoading}
            >
              {maxCapLoading ? (
                <div className={classes.loader}></div>
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </>
      ) : (
        <NoPermisisons />
      )}
    </DefaultLayout>
  );
};

export default TiersListing;
