import { BaseRequest } from "./Request";
import { apiRoute } from "../utils";

export const getWhiteListWallets = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/whitelist-wallets`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const rpcStatusUpdate = async (isActive: number, Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/rpc-url/${Id}`);
  const response = (await baseRequest.patch(url, {
    isActive: isActive,
  })) as any;
  return await response?.json();
};

export const deleteRPCUrl = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/rpc-url/${Id}`);
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};
export const addWhiteListWallets = async (whiteListwallet: { wallet: string; network: string }) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/whitelist-wallets`);
  const response = (await baseRequest.post(url, whiteListwallet)) as any;
  return await response?.json();
};

export const updateWhiteListWallet = async (
  whitelist: { wallet: string; network: string },
  Id: number
) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/whitelist-wallets/${Id}`);
  const response = (await baseRequest.patch(url, whitelist)) as any;
  return await response?.json();
};

export const getWhiteListWalletById = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/whitelist-wallets/${Id}`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const deleteWhitelistWallet = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/whitelist-wallets/${Id}`);
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};