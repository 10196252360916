import React, { useEffect } from "react";
import useStyles from "../style";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import { renderErrorCreatePool } from "../../../utils/validate";
import { Switch } from "antd";
import { withRouter } from "react-router";

function V2Pools(props: any) {
  const classes = useStyles();
  const { setValue, errors, control, poolDetail, isEditDisabled } = props;
  const renderError = renderErrorCreatePool;

  useEffect(() => {
    if (poolDetail && poolDetail.pool_display_version !== undefined) {
      setValue("pool_display_version", !!poolDetail.pool_display_version);
    }
  }, [poolDetail, setValue]);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <label className={classes.formControlLabel}>Show on V2 only</label>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FormControl component="fieldset">
          <Controller
            control={control}
            name="pool_display_version"
            render={(field) => {
              const { value, onChange } = field;
              return (
                <Switch
                  onChange={async (switchValue) => {
                    // eslint-disable-next-line no-restricted-globals
                    await onChange(switchValue);
                  }}
                  checked={value}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  disabled={isEditDisabled}
                />
              );
            }}
          />

          <p className={classes.formErrorMessage}>
            {renderError(errors, "pool_display_version")}
          </p>
        </FormControl>
      </div>
    </div>
  );
}

export default withRouter(V2Pools);