import React, { useEffect, useState } from "react";
import useStyles from "../style";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import { renderErrorCreatePool } from "../../../utils/validate";
import { DatePicker, Switch } from "antd";
import { changeDisplayStatus, handleScheduleTime } from "../../../request/pool";
import { alertSuccess } from "../../../store/actions/alert";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import GenerateLink from "./GenerateLink";
import moment from "moment";
import { DATETIME_FORMAT, isScheduleTime } from "../../../constants";

function DisplayPoolSwitch(props: any) {
  const classes = useStyles();
  const { setValue, errors, control, poolDetail, setPageReload, watch, isEditDisabled } = props;
  const renderError = renderErrorCreatePool;
  const dispatch = useDispatch();
  const [datePickerOpen, setDatePickerOpen] = useState(false); // State to control DatePicker

  useEffect(() => {
    if (poolDetail && poolDetail.is_display != undefined) {
      setValue("is_display", !!poolDetail.is_display);
    }

  }, [poolDetail]);

  useEffect(() => {
    if (poolDetail) {
      // Join Times
      if (poolDetail?.schedule_time) {
        setValue(
          "schedule_time",
          moment(poolDetail?.schedule_time, DATETIME_FORMAT)
        );
      }
    }
  }, [poolDetail, setValue]);

  const changeDisplay = async (value: any) => {
    if (value === false) {
      setPageReload(true);
    }
    const res = await changeDisplayStatus({
      poolId: poolDetail.id,
      isDisplay: value,
    });
    console.log("Change display: Response: ", res);
    if (res.status === 200) {
      dispatch(alertSuccess("Change display setting successful!"));
      if (value === false) {
        window.location.reload();
      }
    } else {
      setPageReload(false);
    }
    return value;
  };

  const scheduleTime = async (value: any) => {
    const res = await handleScheduleTime({
      schedule_time: value,
      poolId:poolDetail?.id
    });
    console.log("Shedule timing: ", res);
    if (res.status === 200) {
      dispatch(alertSuccess("Schedule Timing setting successful!"));
     
    } 
    return value;
  };

  return (
    <div>
        {watch("is_display") === false && (
        <GenerateLink poolDetail={poolDetail} />
        )}
      <div style={{ display: "flex" }}>
        <label className={classes.formControlLabel}>Display</label>
      </div>
      <div style={{ color: "red", fontSize: '14px' }}>
        Users will not see Campaigns while the campaign is in the hidden state
      </div>
      <div style={{ display: "flex", alignItems: "center",marginTop:'10px',width:'fitContent' }}>
        <FormControl component="fieldset">
          <Controller
            control={control}
            name="is_display"
            render={(field) => {
              const { value, onChange } = field;
              return (
                <Switch
                  onChange={async (switchValue) => {
                    // eslint-disable-next-line no-restricted-globals
                    if (!confirm("Do you want change display ?")) {
                      return false;
                    }
                    await onChange(switchValue);
                    await changeDisplay(switchValue);
                  }}
                  checked={value}
                  checkedChildren="Display"
                  unCheckedChildren="Hidden"
                  disabled={isEditDisabled}
                />
              );
            }}
          />

         {
          errors?.is_display &&  <p className={classes.formErrorMessage}>
           {renderError(errors, "is_display")}
         </p>
         }
        </FormControl>

      </div>
      <br />
    </div>
  );
}

export default withRouter(DisplayPoolSwitch);
