import React from 'react';
import useStyles from "../../style";
import {renderErrorCreatePool} from "../../../../utils/validate";
import { UseCheckPermissions } from '../../../../utils/useCheckPermissions';

function WhitelistSocialRequirement(props: any) {
  const classes = useStyles();
  const {
    register, setValue, errors, watch, control,
    poolDetail,
  } = props;
  const renderError = renderErrorCreatePool;
  const isEditTwitterEnabled = UseCheckPermissions("EDIT_ALL_SOCIAL_SETTINGS");

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel} style={{textTransform: 'capitalize'}}>Social Task Link</label>
        <input
          type="text"
          name={'gleam_link'}
          defaultValue={poolDetail?.socialRequirement?.gleam_link}
          ref={register({
            // required: true
          })}
          maxLength={255}
          className={classes.formControlInput}
          disabled= {!isEditTwitterEnabled}
          style = {{
            backgroundColor: !isEditTwitterEnabled ? "#F0F0F0" : "transparent",
          }}
        />
        <p className={classes.formErrorMessage}>
          {
            renderError(errors, 'gleam_link')
          }
        </p>
      </div>
    </>
  );
}

export default WhitelistSocialRequirement;
