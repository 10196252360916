import React from "react";
import useStyles from "../../style";
import TwitterLink from "./TwitterLink";
import TelegramLink from "./TelegramLink";
import MediumLink from "./MediumLink";
import DiscordLink from "./DiscordLink";
import FacebookLink from "./FacebookLink";
import YoutubeLink from "./YoutubeLink";
import { UseCheckPermissions } from "../../../../utils/useCheckPermissions";
import GithubLink from "./GithubLink";
import WhitepaperLink from "./Whitepaper";
import CoinMarketCapLink from "./CoinMarketCap";
import CoinGeckoLink from "./CoinGeckoPage";
import InstagramLink from "./InstagramLink";
import LinkedInLink from "./LinkedIn";
import AuditReport from "./AuditReport";
import ExplorerLink from "./ExplorerLink";
import { SOCIAL_LINKS } from "../../../../constants";

function SocialSetting(props: any) {
  const classes = useStyles();
  const { register, setValue, errors, watch, control, poolDetail } = props;
  const isEditTwitterEnabled = UseCheckPermissions("EDIT_ALL_SOCIAL_SETTINGS");

  return (
    <>
      <div>
        <label className={classes.exchangeRateTitle}>Social Setting</label>
      </div>

      <TwitterLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isEditDisabled={!isEditTwitterEnabled}
      />

      <TelegramLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isEditDisabled={!isEditTwitterEnabled}
      />

      <MediumLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isEditDisabled={!isEditTwitterEnabled}
      />

      <DiscordLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        isEditDisabled={!isEditTwitterEnabled}
      />

      <FacebookLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        isEditDisabled={!isEditTwitterEnabled}
      />

      <YoutubeLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        isEditDisabled={!isEditTwitterEnabled}
      />

      {SOCIAL_LINKS && <> <InstagramLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        isEditDisabled={!isEditTwitterEnabled}
      />

      <LinkedInLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        isEditDisabled={!isEditTwitterEnabled}
      /></>
      }

      <GithubLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        isEditDisabled={!isEditTwitterEnabled}
      />

      {SOCIAL_LINKS && <><WhitepaperLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        isEditDisabled={!isEditTwitterEnabled}
      />

      <CoinMarketCapLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        isEditDisabled={!isEditTwitterEnabled}
      />

      <CoinGeckoLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        isEditDisabled={!isEditTwitterEnabled}
      />

      <ExplorerLink
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        isEditDisabled={!isEditTwitterEnabled}
      />

      <AuditReport
        poolDetail={poolDetail}
        register={register}
        setValue={setValue}
        errors={errors}
        isEditDisabled={!isEditTwitterEnabled}
      /></>}
    </>
  );
}

export default SocialSetting;
