import { TableRow } from "@material-ui/core";
import React from "react";

function EmptyTable({ title }: any) {
  return (
    <tr>
      <td colSpan={12}>
        <p
          style={{
            margin: "0px auto",
            padding: "20px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >{`No content is found in ${title} page`}</p>
      </td>
    </tr>
  );
}

export default EmptyTable;
