import { TextField } from "@material-ui/core";
import React from "react";
import useStyles from "./style";

export default function InputField({ value, onChange, error, id, label, disableButton }: any) {
  const classes = useStyles();
  return (
    <div className={classes.fullWidth}>
      <TextField
        label={label}
        type="text"
        id={id}
        value={value}
        onChange={onChange}
        variant="outlined"
        margin="normal"
        fullWidth
        error={error}
        disabled = {disableButton}
      />
      {error && <div className={classes.error}>{error}</div>}
    </div>
  );
}
