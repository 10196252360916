import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
} from "@material-ui/core";
import React, { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import { DatePicker } from "antd";
import moment from "moment";
import useStyles from "./style";
import { apiRoute, convertToTransaction, SendSolanaTransaction } from "../../../utils";
import { BaseRequest } from "../../../request/Request";

export default function OpenTime({
  vaultAddress,
  pool_index,
  poolId,
  multiSigWallet,
  isValidVault,
  currentCloseTime,
  owner,
  disableButton
}: any) {
  const dispatch = useDispatch();

  const [openTime, setOpenDate] = useState("");
  const [errors, setErrors] = useState<any>("");
  const [loading, setLoading] = useState<any>(false);

  const setOpenTime = async () => {
    if (!window?.solana?.publicKey) {
      dispatch(alertFailure("Please Connect Solana Wallet"));
      return;
    }
    if(!pool_index) {
      dispatch(alertFailure("Pool is not deployed"))
      return;
    }
    if (!multiSigWallet) {
      dispatch(alertFailure("Please Enter MultiSig Wallet Address"));
      return;
    }
    if (!vaultAddress) {
      dispatch(alertFailure("Please Enter Vault Address"));
      return;
    }
    if(owner && !isValidVault) {
      dispatch(alertFailure("Vault Address should be the owner of Pool"));
      return;
    }
    const errors: any = {};
    if (!openTime) {
      dispatch(alertFailure("Please Select Start Time"));
      return;
    }
    if(moment(openTime).unix() >= currentCloseTime.toString()) {
      dispatch(alertFailure("Open Time Should be less than close time"));
      return;
    }
    if (Object.keys(errors).length === 0) {
      try {
        setLoading(true);
        const squads_multi_sig_address = multiSigWallet;
        const baseRequest = new BaseRequest();
        let url = apiRoute(`/solana-multisig/set-open-time`);
        const body = {
          squads_multi_sig_address: squads_multi_sig_address, 
          campaign_id: poolId, 
          close_time: moment(openTime).unix(),
          fee_payer: window?.solana?.publicKey.toString()
      }
        const response = (await baseRequest.post(url, body)) as any;
        const result = await response.json();
        const data = result.data;
        const tx = await convertToTransaction(data)
        const sig = await SendSolanaTransaction(tx);
        dispatch(alertSuccess("Transaction Successfull"));
        setLoading(false);
      } catch (error) {
        dispatch(alertFailure("Transaction failed"));
        setLoading(false);
      }
    } else {
      setErrors(errors);
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.spaceAbove}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Set Start Time
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.columnFlex}>
            <label className={classes.formControlLabel}>Start Time</label>
            <br />
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              showTime={{
                defaultValue: moment("00:00:00", "HH:mm:ss"),
                format: "HH:mm",
              }}
              onSelect={(datetimeSelected: any) => {
                setOpenDate(datetimeSelected);
              }}
              minuteStep={15}
              style={{
                marginBottom: "20px",
              }}
              disabled = {disableButton}
            />
            <br />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                float: "right",
              }}
              onClick={() => setOpenTime()}
              disabled = {disableButton}
            >
              {loading ? (
                <CircularProgress size={25} style={{ marginLeft: 10 }} />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
