import React, { useEffect } from "react";
import useStyles from "../style";
import { renderErrorCreatePool } from "../../../utils/validate";
import { Controller } from "react-hook-form";
import { Select, MenuItem } from "@material-ui/core";

function PoolType(props: any) {
  const classes = useStyles();
  const { errors, poolDetail, control, setValue, isEditDisabled } = props;
  const renderError = renderErrorCreatePool;

  useEffect(() => {
    if (poolDetail && poolDetail?.supported_token_types) {
      setValue("supported_token_types", poolDetail?.supported_token_types);
    }
  }, [poolDetail]);

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Pool Type</label>
        <Controller
          control={control}
          defaultValue="erc-20"
          className={classes.formControlInput}
          name="supported_token_types"
          as={
            <Select
              labelId="supported_token_types"
              id="supported_token_types"
              name="supported_token_types"
              disabled = {isEditDisabled}
            >
              <MenuItem value="erc-20">EVM Chain</MenuItem>
              <MenuItem value="solana">Solana Chain</MenuItem>
            </Select>
          }
        />
        <p className={classes.formErrorMessage}>
          {renderError(errors, "supported_token_types")}
        </p>
      </div>
    </>
  );
}

export default PoolType;
