import React, {ChangeEvent, DragEvent, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';

interface ImageUploaderProps {
    initialSrc?: string;
    onFileSelect: (file: File) => void;
    field: string
}

const ImageUploaderWithForm: React.FC<ImageUploaderProps> = ({initialSrc, field, onFileSelect}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [isDragOver, setIsDragOver] = useState<boolean>(false);
    // Load initialSrc into selectedImage when component mounts
    React.useEffect(() => {
        if (initialSrc) {
            setSelectedImage(initialSrc);
        } else {
            setSelectedImage(null);
        }
    }, [initialSrc]);
    
    const onDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(true);
    };
    
    const onDragLeave = () => {
        setIsDragOver(false);
    };
    
    const onDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(false); // Reset drag over style
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            const file = event.dataTransfer.files[0];
            updateImage(file);
            onFileSelect(file);
        }
    };
    
    const updateImage = (file: File) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setSelectedImage(reader.result as string);
        };
        reader.readAsDataURL(file);
    };
    
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            updateImage(file);
            onFileSelect(file);
        }
    };
    
    const triggerFileSelect = () => fileInputRef.current?.click();
    
    const renderContent = () => {
        if (selectedImage) {
            return <img src={selectedImage} alt="Selected" style={{maxWidth: '100%', height: '300px'}}/>;
        } else {
            return (
                <div style={{padding: '20px'}}>
                    <i className="display-4 text-muted bx bx-cloud-upload"/>
                    <p>Drop image here, <br/>or Upload</p>
                </div>
            );
        }
    };
    
    return (
        <div
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            onClick={triggerFileSelect}
            style={{
                border: '1px dashed gray',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                backgroundColor: isDragOver ? '#e9e9e9' : 'transparent' // Change color on drag over
            }}
        >
            {renderContent()}
            <input type="file" 
            // {...register(field, {
            // })} 
            onChange={onChange} ref={fileInputRef} style={{display: 'none'}} accept=".jpg,.jpeg,.png"/>
        </div>
    );
};

export default ImageUploaderWithForm;
