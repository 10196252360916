export enum GroupName {
  LimitedContent = "Limited Content",
  ExpandedContent = "Expanded Content",
  Logistic = "Logistic",
}

export enum PermissionKey {
  // Limited Content
  ViewIDOPoolList = "LIST_POOL",
  UpdatePoolLimited = "UPDATE_POOL",
  EditShortDescription = "EDIT_SHORT_DESCRIPTION",
  EditAllDescriptionText = "EDIT_ALL_DESCRIPTION_TEXT",
  EditProduct = "EDIT_PRODUCT",
  EditRoadmap = "EDIT_ROADMAP",
  EditBusinessModel = "EDIT_BUSINESS_MODEL",
  EditTeam = "EDIT_TEAM",
  EditPartnersInvestor = "EDIT_PARTNERS_INVESTER",
  EditTokenMetrics = "EDIT_TOKEN_METRICS",
  EditImcValue = "EDIT_IMC_VALUE",
  EditTotalImcValue = "EDIT_TOTAL_IMC_VALUE",
  EditFdvValue = "EDIT_FDV_VALUE",
  LIMITED_CONTENT = "LIMITED_CONTENT",

  // Expanded Content
  EditProjectName = "EDIT_PROJECT_NAME",
  EditPoolBanner = "EDIT_POOL_BANNER",
  EditWebsite = "EDIT_WEBSITE",
  EditRelationshipType = "EDIT_RELATIONSHIP_TYPE",
  EditMarketMaker = "EDIT_MARKET_MAKER",
  EditMarketMakerIcon = "EDIT_MARKET_MAKER_ICON",
  EditRefundTerms = "EDIT_REFUND_TERMS",
  EditTokenSymbol = "EDIT_TOKEN_SYMBOL",
  EditTokenIcon = "EDIT_TOKEN_ICON",
  EditCoinGeckoID = "EDIT_COINGECKO_ID",
  EditATHPrice = "EDIT_ATH_PRICE",
  EditAllSocialSettings = "EDIT_ALL_SOCIAL_SETTINGS",
  UpdatePoolExpanded = "UPDATE_POOL",
  EXPANDED_CONTENT = "EXPANDED_CONTENT",

  // Logistic
  Display = "DISPLAY_TOGLE",
  LockUnlockPool = "LOCK_POOL",
  EditKYCBpypass = "KYC_BYPASS",
  EditTotalTokenAmount = "EDIT_TOTAL_TOKEN_AMOUNT",
  EditPoolNetwork = "EDIT_POOL_NETWORK",
  EditTokenAddress = "EDIT_TOKEN_ADDRESS",
  EditExchangeRate = "EDIT_EXCHANGE_RATE",
  EditShowPricePerToken = "EDIT_SHOW_PRICE_PER_TOKEN",
  EditForbiddenCountries = "EDIT_FORBIDEN_COUNTRIES",
  EditTimeSettingsTab = "EDIT_TIME_SETTING",
  EditUsersListTab = "USER_LIST_TAB",
  UpdatePoolLogistic = "UPDATE_POOL",
  TokenSoldDisplay = "TOKEN_SOLD_DISPLAY",
  LOGISTIC = "LOGISTICS",

  // Single Permissions
  CloneCreateIDOPools = "CREATE_POOL",
  DeployIDOPools = "DEPLOY_POOL",
  EditTokenReceivingAddress = "EDIT_RECEIVE_MONEY_ADDRESS",
  ViewUserList = "VIEW_USER_LIST",
  EditUserList = "EDIT_USER_LIST",
  ViewKYCUserList = "VIEW_KYC_LIST",
  EditKYCUser = "EDIT_KYC_LIST",
  ViewStakingPoolsList = "VIEW_STAKING_POOL",
  EditStakingPools = "EDIT_STAKING_POOL",
  ViewAdminUserList = "LIST_ADMIN",
  ViewRPCURLList = "VIEW_RPC_URLS",
  EditRPCURLList = "EDIT_RPC_URLS",
  ViewTiersRangeList = "VIEW_TIER_RANGES",
  EditTierRange = "EDIT_TIER_RANGES",
  EditConfigPage = "EDIT_CONFIG_SECTION",
  ViewConfigPage = "VIEW_CONFIG_SECTION",
  ViewWhitelistWallets = "VIEW_WHITE_LIST_WALLETS",
  EditWhitelistWallets = "EDIT_WHITE_LIST_WALLETS",
  ViewSolanaMultiSig = "VIEW_SOLANA_MULTISIG",
  EditSolanaMultiSig = "EDIT_SOLANA_MULTISIG",
  DepositToken = "DEPOSIT_TOKEN",
  DepositCurrency = "DEPOSIT_CURRENCY",
  WithdrawRemainingTokenAmount = "WITHDRAW_REMAINING_TOKEN_AMOUNT",
  WithdrawRemainingRefundAmount = "WITHDRAW_REMAINING_REFUND_AMOUNT",
  DepositRefundingAmount = "DEPOSIT_REFUNDING_AMOUNT",
  PoolType = "POOL_TYPE",
  MinimumTierForPool = "MINIMUM_TIER_FOR_POOL",
  MinimumTierForPrePurchase = "MINIMUM_TIER_FOR_PRE_PURCHASE",
  TierBaseVisibility = "TIER_BASE_VISIBILITY",
}

export enum PermissionLabel {
  LIST_POOL = "View IDO Pool List",
  VIEW_IDO_POOL_DETAIL = "View IDO Pool Detail",
  EDIT_SHORT_DESCRIPTION = "Edit Short Description",
  EDIT_ALL_DESCRIPTION_TEXT = "Edit All Description Text",
  EDIT_PRODUCT = "Edit Product",
  EDIT_ROADMAP = "Edit Roadmap",
  EDIT_BUSINESS_MODEL = "Edit Business Model",
  EDIT_TEAM = "Edit Team",
  EDIT_PARTNERS_INVESTOR = "Edit Partners Investor",
  EDIT_TOKEN_METRICS = "Edit Token Metrics",
  UPDATE_POOL_LIMITED = "Update Pool (Limited)",
  UPDATE_POOL_EXPANDED = "Update Pool (Expanded)",
  EDIT_PROJECT_NAME = "Edit Project Name",
  EDIT_POOL_BANNER = "Edit Pool Banner",
  EDIT_WEBSITE = "Edit Website",
  EDIT_RELATIONSHIP_TYPE = "Edit Relationship Type",
  EDIT_MARKET_MAKER = "Edit Market Maker",
  EDIT_MARKET_MAKER_ICON = "Edit Market Maker Icon",
  EDIT_REFUND_TERMS = "Edit Refund Terms",
  EDIT_TOKEN_SYMBOL = "Edit Token Symbol",
  EDIT_TOKEN_ICON = "Edit Token Icon",
  EDIT_COIN_GECKO_ID = "Edit CoinGecko ID",
  EDIT_ATH_PRICE = "Edit ATH Price",
  EDIT_ALL_SOCIAL_SETTINGS = "Edit All Social Settings",
  DISPLAY_TOGLE = "Display",
  LOCK_POOL = "Lock/Unlock Pool",
  KYC_BYPASS = "Edit KYC Bypass",
  EDIT_TOTAL_TOKEN_AMOUNT = "Edit Total Token Amount",
  EDIT_POOL_NETWORK = "Edit Pool Network",
  EDIT_TOKEN_ADDRESS = "Edit Token Address",
  EDIT_EXCHANGE_RATE = "Edit Exchange Rate",
  EDIT_SHOW_PRICE_PER_TOKEN = "Edit Show Price Per Token",
  EDIT_FORBIDEN_COUNTRIES = "Edit Forbidden Countries",
  EDIT_TIME_SETTING = "Edit Time Settings Tab",
  USER_LIST_TAB = "Edit Users List Tab",
  EDIT_PARTNERS_INVESTER = "Edit Partners Investor",
  EDIT_TOKEN_METRICS_TAB = "Edit Token Metrics Tab",
  EDIT_PROJECT_SETTINGS_TAB = "Edit Project Settings Tab",
  CREATE_POOL = "Clone/Create IDO Pools",
  DEPLOY_POOL = "Deploy IDO Pools",
  EDIT_RECEIVE_MONEY_ADDRESS = "Edit Token Receiving Address",
  VIEW_USER_LIST = "View User List",
  EDIT_USER_LIST = "Edit User List",
  VIEW_KYC_LIST = "View KYC User List",
  EDIT_KYC_LIST = "Edit KYC User",
  VIEW_STAKING_POOL = "View Staking Pools List",
  EDIT_STAKING_POOL = "Edit Staking Pools",
  LIST_ADMIN = "View Admin List",
  VIEW_RPC_URLS = "View RPC URL List",
  EDIT_RPC_URLS = "Edit RPC URL List",
  VIEW_TIER_RANGES = "View Tiers Range List",
  EDIT_TIER_RANGES = "Edit Tier Range",
  EDIT_CONFIG_SECTION = "Edit Config Page",
  VIEW_CONFIG_SECTION = "View Config Page",
  UPDATE_POOL = 'Update Pool',
  EDIT_COINGECKO_ID = 'Edit Coingeko ID',
  VIEW_WHITE_LIST_WALLETS = "View Whitelist Wallets",
  EDIT_WHITE_LIST_WALLETS = "Edit Whitelist Wallets",
  LIMITED_CONTENT = 'Limited Content',
  EXPANDED_CONTENT = 'Expanded Content',
  LOGISTICS = 'Logistic',
  DEPOSIT_TOKEN = "Deposit Token",
  DEPOSIT_CURRENCY = "Deposit Currency",
  WITHDRAW_REMAINING_TOKEN_AMOUNT = "Withdraw Remaining Token Amount",
  WITHDRAW_REMAINING_REFUND_AMOUNT = "Withdraw Remaining Refund Amount",
  DEPOSIT_REFUNDING_AMOUNT = "Deposit Refunding Amount",
  POOL_TYPE = "Pool Type",
  MINIMUM_TIER_FOR_POOL = "Minimum Tier For Pool",
  MINIMUM_TIER_FOR_PRE_PURCHASE = "Minimum Tier For Pre Purchase",
  TIER_BASE_VISIBILITY = "Tier Base Visibility",
  TOKEN_SOLD_DISPLAY = "Token Sold Display",
  EDIT_IMC_VALUE = "Edit IMC Value",
  EDIT_TOTAL_IMC_VALUE = "Edit Total IMC Value",
  EDIT_FDV_VALUE = "Edit FDV Value",
  VIEW_SOLANA_MULTISIG = "View Solana Multi Sig",
  EDIT_SOLANA_MULTISIG = "Edit Solana Multi Sig",

}
