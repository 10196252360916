import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import ColorInput from "./ColorInput";
import { addThemeColors, getThemeColors } from "../../request/faq";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import { fetchApiData, saveApiData } from "../../request/config/index";
import {
  GET_THEME_OPTIONS,
  SAVE_THEME_OPTIONS,
} from "../../request/config/Urls";

interface Colors {
  primary: string;
  secondary: string;
  primary_text: string;
  secondary_text: string;
  meta_mask: string;
  main_background: string;
  secondary_background: string;
  primary_gradient: string;
  primary_gradient_two: string;
  secondary_gradient: string;
  secondary_gradient_two: string;
  linear_gradient_primary_one: string;
  linear_gradient_primary_two: string;
  linear_gradient_secondary_one: string;
  linear_gradient_secondary_two: string;
}

const ThemeOptionsComponent = () => {
  const [colors, setColors] = useState<Colors>({
    linear_gradient_primary_one: "",
    linear_gradient_primary_two: "",
    linear_gradient_secondary_one: "",
    linear_gradient_secondary_two: "",
    main_background: "",
    meta_mask: "",
    primary: "",
    primary_gradient: "",
    primary_gradient_two: "",
    primary_text: "",
    secondary: "",
    secondary_background: "",
    secondary_gradient: "",
    secondary_gradient_two: "",
    secondary_text: "",
  });

  const dispatch = useDispatch();

  const getColors = async () => {
    const response = await fetchApiData({ url: GET_THEME_OPTIONS });
    setColors(response.data[0]);
  };
  const save = async (e: any) => {
    e.preventDefault();
    try {
      await saveApiData({ url: SAVE_THEME_OPTIONS, body: colors });
      dispatch(alertSuccess("Colors saved successfully"));
      getColors();
    } catch (error: any) {
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error Saving Colors"}`
        )
      );
    }
  };
  const handleColorChange = (colorName: keyof Colors, colorValue: string) => {
    setColors((prevColors) => ({
      ...prevColors,
      [colorName]: colorValue,
    }));
  };
  useEffect(() => {
    getColors();
  }, []);
  return (
    <div>
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody className="p-4">
              {/* Group 1: Primary and Secondary Colors */}
              {/* <center className="mt-3 mb-3"><h5><b>Button</b></h5></center> */}
              <div className="row">
                <ColorInput
                  label="Primary"
                  value={colors.primary}
                  title={"Choose Primary Base Button Color"}
                  onChange={(e: any) =>
                    handleColorChange("primary", e.target.value)
                  }
                />
                <ColorInput
                  label="Secondary"
                  value={colors.secondary}
                  title={"Choose Secondary Base Button Color"}
                  onChange={(e: any) =>
                    handleColorChange("secondary", e.target.value)
                  }
                />
                <hr />
                {/* <center className="mt-3 mb-3"><h5><b>Text</b></h5></center> */}
                <ColorInput
                  label="Primary Text"
                  value={colors.primary_text}
                  title={"Choose Buy Now Primary Text Color"}
                  onChange={(e: any) =>
                    handleColorChange("primary_text", e.target.value)
                  }
                />
                <ColorInput
                  label="Secondary Text"
                  value={colors.secondary_text}
                  title={"Choose Secondary Text"}
                  onChange={(e: any) =>
                    handleColorChange("secondary_text", e.target.value)
                  }
                />
                <hr />
                {/* <center className="mt-3 mb-3"><h5><b>Metamask</b></h5></center> */}
                <ColorInput
                  label="Meta Mask"
                  value={colors.meta_mask}
                  title={"Choose the button Meta Mask"}
                  onChange={(e: any) =>
                    handleColorChange("meta_mask", e.target.value)
                  }
                />
              </div>
              <hr />
              {/* Group 2: Background Colors and Gradients */}
              {/* <center className="mt-3 mb-3"><h5><b>Background</b></h5></center> */}
              <div className="row mb-4">
                <ColorInput
                  label="Main Background"
                  value={colors.main_background}
                  title={"Choose the buyToken Main Background"}
                  onChange={(e: any) =>
                    handleColorChange("main_background", e.target.value)
                  }
                />
                <ColorInput
                  label="Secondary Background"
                  value={colors.secondary_background}
                  title={"Choose the secondary background of theme"}
                  onChange={(e: any) =>
                    handleColorChange("secondary_background", e.target.value)
                  }
                />
                <hr />
                {/* <center className="mt-3 mb-3"><h5><b>Gradient 1</b></h5></center> */}
                <ColorInput
                  label="First Gradient"
                  value={colors.primary_gradient}
                  title={"Choose the color of the primary button Gradient"}
                  onChange={(e: any) =>
                    handleColorChange("primary_gradient", e.target.value)
                  }
                />

                <ColorInput
                  label="Second Gradient"
                  value={colors.secondary_gradient}
                  title={"Choose the color of the button Secondary Gradient"}
                  onChange={(e: any) =>
                    handleColorChange("secondary_gradient", e.target.value)
                  }
                />

                <hr />
              </div>

              <div className="mt-5">
                <button
                  type="submit"
                  className="btn btn-info w-md"
                  onClick={save}
                >
                  Save
                </button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ThemeOptionsComponent;
