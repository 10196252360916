// @ts-ignore
import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import { renderErrorCreatePool } from "../../../../utils/validate";
import useStyles from "../../style";
import { Editor } from "@tinymce/tinymce-react";

function ClaimGuide(props: any) {
  const classes = useStyles();
  const { register, setValue, errors, watch, getValues, poolDetail, control } =
    props;
  const renderError = renderErrorCreatePool;
  const isDeployed = !!poolDetail?.is_deploy;
  const editorRef = useRef(null);
  const onChange = (field: any) => {
    if (editorRef.current) {
      const data = editorRef.current.getContent();
      setValue(field.name, data);
    }
  };

  const timyInitConfig = {
    height: 500,
    menubar: true,
    plugins: [
      "advlist",
      "autolink",
      "lists",
      "link",
      "image",
      "charmap",
      "preview",
      "anchor",
      "searchreplace",
      "visualblocks",
      "code",
      "fullscreen",
      "insertdatetime",
      "media",
      "table",
      "code",
      "help",
      "wordcount",
    ],
    toolbar:
      "undo redo | blocks | " +
      "bold italic forecolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat | help",
  };

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.exchangeRateTitle}>Claim Guide</label>
        <Controller
          control={control}
          rules={{
            required: false,
          }}
          name="claim_guide"
          render={(field) => {  
            return (
              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                onInit={(evt: any, editor) => (editorRef.current = editor)}
                initialValue={poolDetail.claim_guide}
                init={timyInitConfig}
                onEditorChange={(evt: any, editor: any) => onChange(field)}
              />
            );
          }}
        />
        <p className={classes.formErrorMessage}>
          {renderError(errors, "claim_guide")}
        </p>
      </div>
    </>
  );
}

export default ClaimGuide;
