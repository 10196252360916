import React from "react";

// Utility function to check if a string is valid JSON
const isJSON = (str: any) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const DisplayObject = ({ obj, indent = 0 }: any) => {
  const renderObject = (obj: any, indent: any) => {
    return Object.entries(obj).map(([key, value]: any) => {
      const indentation = { marginLeft: `${indent * 10}px` };

      if (typeof value === "object" && value !== null) {
        return (
          <div key={key}>
            <div style={indentation}>
              {key}: {"{"}
            </div>
            {renderObject(value, indent + 1)}
            <div style={indentation}>{"}"}</div>
          </div>
        );
      } else if (Array.isArray(value)) {
        return (
          <div key={key} style={indentation}>
            {key}: [{value.join(", ")}]
          </div>
        );
      } else {
        return (
          <div key={key} style={indentation}>
            {key}: {value !== null ? value.toString() : "null"}
          </div>
        );
      }
    });
  };

  return (
    <pre 
    style={{
      marginTop:'70px'
    }}
    >
      {typeof obj === "string" ? obj : ""}
      {typeof obj === "string" && isJSON(obj)
        ? renderObject(JSON.parse(obj), indent)
        : renderObject(obj, indent)}
    </pre>
  );
};
