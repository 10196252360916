import React from "react";
import ThemeOptionsComponent from "./ThemeOptionsComponent";
import Breadcrumb from "../../components/Base/BreadCrumbs";
import DefaultLayout from "../../components/Layout/DefaultLayout";

const ThemeOptions = () => {
  return (
    <DefaultLayout>
      <div className="page-content">
        <Breadcrumb title="Theme Options" breadcrumbItem="Theme Options" />
        <ThemeOptionsComponent />
      </div>
    </DefaultLayout>
  );
};

export default ThemeOptions;
