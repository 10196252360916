import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import CustomButton from "./Button";
import InputField from "./TextField";
import useStyles from "./style";
import CustomAccordion from "./CustomAccordion";
import { apiRoute, convertToTransaction, SendSolanaTransaction } from "../../../utils";
import { BaseRequest } from "../../../request/Request";

export default function SetOfferedTokenDecimals({
  vaultAddress,
  pool_index,
  poolId,
  multiSigWallet,
  isValidVault,
  owner,
  disableButton
}: any) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [loading, setLoading] = useState<any>(false);
  const [decimals, setDecimals] = useState<number>(0);
  const [tokenMint, setTokenMint] = useState("");
  const [errors, setErrors] = useState<any>({});

  const handleDecimalChange = (e: any) => {
    setDecimals(e.target.value);
    if (decimals) {
      setErrors((prevErrors : any) => {
        const { decimals, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  };

  const handleTokenMintChange = (e: any) => {
    setTokenMint(e.target.value);
    if (tokenMint) {
      setErrors((prevErrors : any) => {
        const { tokenMint, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  };

  useEffect(() => {
    if(decimals) {
      setErrors((prevErrors : any) => {
        const { decimals, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [decimals])
  useEffect(() => {
    if(tokenMint) {
      setErrors((prevErrors : any) => {
        const { tokenMint, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [tokenMint])

  const OfferedTokenDecimals = async () => {
    if(!window?.solana?.publicKey) {
      dispatch(alertFailure("Please Connect Solana Wallet"))
      return;
    }
    if(!pool_index) {
      dispatch(alertFailure("Pool is not deployed"))
      return;
    }
    if(!multiSigWallet) {
      dispatch(alertFailure("Please Enter MultiSig Wallet Address"))
      return;
    }
    if(!vaultAddress) {
      dispatch(alertFailure("Please Enter Vault Address"))
      return;
    }
    if(owner && !isValidVault) {
      dispatch(alertFailure("Vault Address should be the owner of Pool"));
      return;
    }
    const errors: any = {};
    if (!decimals) {
      errors.decimals = "Decimals is required";
    }
    if (!tokenMint) {
      errors.fundingAccount = "Token Mint is required";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true)
      try {
        const squads_multi_sig_address = multiSigWallet;
        const baseRequest = new BaseRequest();
        let url = apiRoute(`/solana-multisig/set-offered-token-decimals`);
        const body = {
          squads_multi_sig_address: squads_multi_sig_address, 
          campaign_id: poolId, 
          decimals: decimals,
          token_mint: tokenMint,
          fee_payer: window?.solana?.publicKey.toString()
      }
        const response = (await baseRequest.post(url, body)) as any;
        const result = await response.json();
        const data = result.data;
        const tx = await convertToTransaction(data)
        const sig = await SendSolanaTransaction(tx);
        dispatch(alertSuccess("Transaction Successfull"));
        setDecimals(0);
        setTokenMint("");
        setLoading(false);
      } catch (error) {
        dispatch(alertFailure("Transaction Failed"));
        setDecimals(0);
        setTokenMint("");
        setLoading(false);
      }
    } else {
      setErrors(errors);
    }
  };
  return (
    <div className={classes.spaceAbove}>
      <CustomAccordion title="Set Offered Token Decimals">
        <div className={classes.columnFlex}>
          <InputField
            value={decimals}
            onChange={handleDecimalChange}
            error={errors.decimals}
            label="Enter Decimals"
            id="decimals"
            disableButton = {disableButton}
          />
          <InputField
            value={tokenMint}
            onChange={handleTokenMintChange}
            error={errors.tokenMint}
            label="Enter Token Mint"
            id="tokenMint"
            disableButton = {disableButton}
          />
          <CustomButton disableButton = {disableButton} loading = {loading} onClick={() => OfferedTokenDecimals()} />
        </div>
      </CustomAccordion>
    </div>
  );
}
