import React from "react";
import PrivacyPolicyComponent from "./PrivacyPolicyComponent";
import Breadcrumb from "../../components/Base/BreadCrumbs";
import DefaultLayout from "../../components/Layout/DefaultLayout";

const PrivacyPolicy = () => {
  return (
    <DefaultLayout>
      <div className="page-content">
        <Breadcrumb title="Privacy Policy" breadcrumbItem="Privacy Policy" />
        <PrivacyPolicyComponent />
      </div>
    </DefaultLayout>
  );
};

export default PrivacyPolicy;
