import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Pagination from "@material-ui/lab/Pagination";
//@ts-ignore
import { useDebounce } from "use-debounce";

import { convertDateTimeToUnix } from "../../utils/convertDate";
import useStyles from "./style";
import { useCommonStyle } from "../../styles";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { UseCheckPermissions } from "../../utils/useCheckPermissions";
import NoPermisisons from "../../components/Base/NoPermissions";
import SolanaRecord from "./SolanaRecord";
import { BaseRequest } from "../../request/Request";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import EmptyTable from "../../components/Base/Emptytable";
const queryString = require("query-string");

const tableHeaders = [
  "POOL NAME",
  "NETWORK",
  "START TIME",
  "FINISH TIME",
  "TOKEN SYMBOL",
  "STATUS",
];

const SolanaMultiSig: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const commonStyle = useCommonStyle();

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [failure, setFailure] = useState(false);

  const [filter, setFilter] = useState(false);
  const [currentOpen, setCurrentOpen] = useState("");
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [finishTime, setFinishTime] = useState<Date | null>(null);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(page);
  const [isDisplay, setIsDisplay]: any = useState(true);

  useEffect(() => {
    handleCampaignQuery(
      currentPage,
      query,
      startTime,
      finishTime,
      filter,
      isDisplay
    );
  }, [dispatch, currentPage, query, startTime, finishTime, filter]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleCampaignSearch = (event: any) => {
    setCurrentPage(1);
    setQuery(event.target.value);
  };

  const handleCampaignQuery = (
    currentPage: number,
    query: string,
    startTime: Date | null,
    finishTime: Date | null,
    filter: boolean,
    isDisplay: boolean | number
  ) => {
    getCampaigns(
      currentPage,
      query,
      convertDateTimeToUnix(startTime),
      convertDateTimeToUnix(finishTime),
      filter,
      !!isDisplay
    );
  };

  const [delayCampaignSearch] = useDebounce(handleCampaignSearch, 500);

  const handleCurrentOpenSet = (id: string) => {
    setCurrentOpen(id);
  };

  const { data: loginUser } = useTypedSelector((state) => state.user);

  const getCampaigns = async (
    currentPage: number = 1,
    query: string = "",
    startTime: string,
    finishTime: string,
    filter: boolean = false,
    is_display: boolean = false
  ) => {
    setLoading(true);
    const baseRequest = new BaseRequest();
    let url = `/campaigns`;
    const queryParams = {
      page: currentPage,
      title: query,
      start_time: startTime,
      finish_time: finishTime,
      registed_by: null,
      is_display: is_display ? 1 : 0,
      network_available: "solana",
    };
    if (filter) {
      queryParams.registed_by = loginUser;
    }
    url += "?" + queryString.stringify(queryParams);

    try {
      const response = (await baseRequest.get(url)) as any;
      const resObject = await response.json();

      if (resObject.status === 200) {
        const { total, page, lastPage, data } = resObject.data;
        setCampaigns(data);
        setLastPage(lastPage);
        setPage(page);
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      setFailure(true);
    }
  };

  return (
    <DefaultLayout>
      {UseCheckPermissions("VIEW_SOLANA_MULTISIG") ? (
        <>
          <div className={classes.header}>
            <div className={classes.headerRight}>
              <div className={commonStyle.boxSearch}>
                <input
                  className={commonStyle.inputSearch}
                  onChange={delayCampaignSearch}
                  placeholder="Search"
                />
                <img
                  className={commonStyle.iconSearch}
                  src="/images/icon-search.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <TableContainer component={Paper} className={classes.tableContainer}>
            {loading ? (
              [...Array(10)].map((num, index) => (
                <div key={index}>
                  <Skeleton className={classes.skeleton} width={"100%"} />
                </div>
              ))
            ) : (
              <Table component="div" className={classes.table}>
                <TableHead component="div">
                  <TableRow component="div">
                    {tableHeaders.map((tableHeader: string, index: number) => (
                      <TableCell
                        component="div"
                        key={index}
                        className={classes.tableHeader}
                      >
                        {tableHeader}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody component="div" className={classes.tableBody}>
                  {campaigns && campaigns.length > 0 ? (
                    campaigns.map((campaign: any, index: number) => (
                      <SolanaRecord
                        key={campaign.id}
                        currentOpen={currentOpen}
                        setCurrentOpen={handleCurrentOpenSet}
                        campaign={campaign}
                      />
                    ))
                  ) : (
                    <EmptyTable title="Solana Multisig" />
                  )}
                </TableBody>
              </Table>
            )}
            {failure ? (
              <p className={classes.errorMessage}>{failure}</p>
            ) : (!campaigns || campaigns.length === 0) && !loading ? (
              <p className={classes.noDataMessage}>There is no data</p>
            ) : (
              <>
                {campaigns && campaigns.length > 0 && lastPage > 1 && (
                  <Pagination
                    page={currentPage}
                    className={classes.pagination}
                    count={lastPage}
                    onChange={handlePaginationChange}
                  />
                )}
              </>
            )}
          </TableContainer>
        </>
      ) : (
        <NoPermisisons />
      )}
    </DefaultLayout>
  );
};

export default SolanaMultiSig;
