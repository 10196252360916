import { menuActions } from "../constants/nav";


export const setMenu = (menuIndex: number) => ({
  type: menuActions.SET_MENU,
  payload: menuIndex,
});

export const setSubMenu = (menuIndex: number, subMenuIndex: number) => ({
  type: menuActions.SET_SUBMENU,
  payload: { menuIndex, subMenuIndex },
});

export const resetMenu = () => ({
  type: menuActions.RESET_MENU,
});
