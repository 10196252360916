// actions/menuActions.ts
const SET_MENU = 'SET_MENU';
const SET_SUBMENU = 'SET_SUBMENU';
const RESET_MENU = 'RESET_MENU';

export const menuActions = {
  SET_MENU,
  SET_SUBMENU,
  RESET_MENU,
};


