import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import useStyles from "./style";
import CustomAccordion from "./CustomAccordion";
import InputField from "./TextField";
import CustomButton from "./Button";
import { apiRoute, convertToTransaction, deriveTokenAccount, SendSolanaTransaction } from "../../../utils";
import { BaseRequest } from "../../../request/Request";

export default function RefundRemainingTokenCurrency({
  vaultAddress,
  pool_index,
  poolId,
  multiSigWallet,
  isValidVault,
  owner,
  disableButton
}: any) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [walletAddress, setWalletAddress] = useState("");
  const [currencyAddress, setCurrencyAddress] = useState("");
  const [errors, setErrors] = useState<any>("");
  const [loading, setLoading] = useState<any>(false);

  const handleWalletAddressChange = (e: any) => {
    if (walletAddress) {
      setErrors((prevErrors: any) => {
        const { walletAddress, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
    setWalletAddress(e.target.value);
  };

  const handleCurrencyAddressChange = (e: any) => {
    if (currencyAddress) {
      setErrors((prevErrors: any) => {
        const { currencyAddress, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
    setCurrencyAddress(e.target.value);
  };

  useEffect(() => {
    if (currencyAddress) {
      setErrors((prevErrors: any) => {
        const { currencyAddress, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [currencyAddress]);
  useEffect(() => {
    if (walletAddress) {
      setErrors((prevErrors: any) => {
        const { walletAddress, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [walletAddress]);

  const refundRemainingTokenCurrency = async () => {
    if (!window?.solana?.publicKey) {
      dispatch(alertFailure("Please Connect Solana Wallet"));
      return;
    }
    if(!pool_index) {
      dispatch(alertFailure("Pool is not deployed"))
      return;
    }
    if (!multiSigWallet) {
      dispatch(alertFailure("Please Enter MultiSig Wallet Address"));
      return;
    }
    if (!vaultAddress) {
      dispatch(alertFailure("Please Enter Vault Address"));
      return;
    }
    if(owner && !isValidVault) {
      dispatch(alertFailure("Vault Address should be the owner of Pool"));
      return;
    }
    const errors: any = {};
    if (!walletAddress) {
      errors.walletAddress = "Wallet Address is required";
    }
    if (!currencyAddress) {
      errors.currencyAddress = "Currency Address is required";
    }
    if (Object.keys(errors).length === 0) {
      try {
        setLoading(true)
        const tokenAddress = deriveTokenAccount(walletAddress, currencyAddress).toBase58();
        const squads_multi_sig_address = multiSigWallet;
        const baseRequest = new BaseRequest();
        let url = apiRoute(`/solana-multisig/refund-remaining-token-currency`);
        const body = {
          squads_multi_sig_address: squads_multi_sig_address, 
          campaign_id: poolId, 
          wallet: tokenAddress,
          fee_payer: window?.solana?.publicKey.toString(),
          currency: currencyAddress
      }
        const response = (await baseRequest.post(url, body)) as any;
        const result = await response.json();
        const data = result.data;
        const tx = await convertToTransaction(data)
        const sig = await SendSolanaTransaction(tx);
        dispatch(alertSuccess("Transaction Successfull"));
        setCurrencyAddress("");
        setWalletAddress("");
        setLoading(false);
      } catch (error) {
        dispatch(alertFailure("Transaction failed"));
        setCurrencyAddress("");
        setWalletAddress("");
        setLoading(false);
      }
    } else {
      setErrors(errors);
    }
  };
  return (
    <div className={classes.spaceAbove}>
      <CustomAccordion title="Refund Remaining Token Currency">
        <div className={classes.columnFlex}>
          <InputField
            label="Enter Wallet Address"
            id="tokenMint"
            value={walletAddress}
            onChange={handleWalletAddressChange}
            error={errors.walletAddress}
            disableButton = {disableButton}
          />
          <InputField
            label="Enter Currency Address"
            id="currencyAddress"
            value={currencyAddress}
            onChange={handleCurrencyAddressChange}
            error={errors.currencyAddress}
            disableButton = {disableButton}
          />
          <CustomButton disableButton = {disableButton} loading = {loading} onClick={() => refundRemainingTokenCurrency()} />
        </div>
      </CustomAccordion>
    </div>
  );
}
