import React from "react";
import useStyles from "./style";
import { Button, CircularProgress } from "@material-ui/core";

export default function CustomButton({ onClick, loading, disableButton }: any) {
  const classes = useStyles();
  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      className={classes.rightAlign}
      onClick={onClick}
      disabled = {disableButton}
    >
      {loading ? (
        <CircularProgress size={25} style={{ marginLeft: 10 }} />
      ) : (
        "Submit"
      )}
    </Button>
  );
}
