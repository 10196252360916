import { Grid } from "@material-ui/core";
import {
  FEATURE_POOL,
  GIVEAWAY_VERSION,
  isScheduleTime,
  NETWORK_AVAILABLE,
  POOL_IS_PRIVATE,
  SOLANA,
  TON_NETWORK,
  TRANSFER_OWNERSHIP,
  V2PoolsToggle,
} from "../../../constants";
import AcceptCurrency from "../Components/AcceptCurrency";
import AddressReceiveMoney from "../Components/AddressReceiveMoney";
import AirdropNetwork from "../Components/AirdropNetwork";
import BuyType from "../Components/BuyType";
import DisplayPoolSwitch from "../Components/DisplayPoolSwitch";
import ExchangeRate from "../Components/ExchangeRate";
import ForbiddenCountry from "../Components/ForbiddenCountry/ForbiddenCountry";
import KycRequired from "../Components/KycRequired";
import NetworkAvailable from "../Components/NetworkAvailable";
import ParticipantNumber from "../Components/ParticipantNumber";
import PoolBanner from "../Components/PoolBanner";
import PoolDescription from "../Components/PoolDescription";
import PoolHash from "../Components/PoolHash";
import PoolClaimHash from "../Components/PoolClaimHash";
import PoolName from "../Components/PoolName";
import PoolWebsite from "../Components/PoolWebsite";
import PoolRelationship from "../Components/PoolRelationship";
import PoolMarketMaker from "../Components/PoolMarketMaker";
import PoolMarketMakerIcon from "../Components/PoolMarketMakerIcon";
import PrivatePoolSetting from "../Components/PrivatePoolSetting";
import SocialSetting from "../Components/SocialSetting/SocialSetting";
import TokenAddress from "../Components/TokenAddress";
import TokenLogo from "../Components/TokenLogo";
import TokenCoingeckoId from "../Components/TokenCoingeckoId";

import TokenSymbol from "../Components/TokenSymbol";
import TotalCoinSold from "../Components/TotalCoinSold";
import GleamRequirement from "../Components/WhitelistSocialRequirement/GleamRequirement";
import useStyles from "../style";
import ClaimNetwork from "../Components/ClaimNetwork";
import { useMemo, useState } from "react";
import ProgressDisplaySetting from "../Components/ProgressDisplaySetting/ProgressDisplaySetting";
import DepositRefundBalance from "../Components/DepositRefundBalance/DepositBalance";
import WithdrawRemainingToken from "../Components/WithdrawRemainingToken";
import WithdrawRefundBalance from "../Components/WithdrawRefundBalance/WithdrawBalance";
import ShortDescription from "../Components/ShortDescription";
import React from "react";
// import ATHPrice from "../Components/ATHPrice";
import StaticATHRoi from "../Components/StaticATHRoi";
import DepositToken from "../Components/DepositToken/DepositToken";
import TokenPriceGiveaway from "../Components/TokenPriceGiveaway";
import RefundTerms from "../Components/RefundTerms";
import LockPool from "../Components/LockPool";
import TransferOwnership from "../Components/TransferOwnership";
import PoolType from "../Components/PoolType";
import SolanaNetworkAvailable from "../Components/SolanaNetworkAvailable";
import SolanaClaimNetwork from "../Components/SolanaClaimNetwork";
import SolanaAcceptCurrency from "../Components/SolanaAcceptCurrency";
import SolanaDepositRefundBalance from "../Components/SolanaDepositRefundBalance/SolanaDepositBalance";
import SolanaWithdrawRefundBalance from "../Components/SolanaWithdrawRefundBalance";
import SolanaWithdrawRemainingToken from "../Components/SolanaWithdrawRemainingTokens";
import SolanaDepositToken from "../Components/SolanaDepositToken/SolanaDepositToken";
import InitialMarketCapitilization from "../Components/InitialMarketCapitilization";
import TotalInitialMarketCapitilization from "../Components/TotalInitialMarketCapitilization";
import FullyDelutedValuationComponent from "../Components/FullyDelutedValuationComponent";
import SolanaPoolHash from "../Components/SolanaPoolHash";
import UserTier from "../Components/UserTier";
import { UseCheckPermissions } from "../../../utils/useCheckPermissions";
import SolanaTransferOwnership from "../Components/SolanaTransferOwnership";
import DepositCurrency from "../Components/DepositCurrency/DepositCurrency";
import FeaturedPool from "../Components/FeaturedPool";
import TonDistribution from "../Components/TonDistribution";
import TonInputBox from "../Components/TonInputBox";
import TonAddressToggle from "../Components/TonAddressToggle";
import ClaimLink from "../Components/ClaimLink";
import ClaimLinkInput from "../Components/ClaimLinkInput";
import DisplayScheduleTime from "../Components/DisplayScheduleTime";
import V2Pools from "../Components/V2Pools";
import PoolIcon from "../Components/PoolIcon";

const TabProjectInfo = (props: any) => {
  const classes = useStyles();

  const {
    showTab,
    poolDetail,
    setValue,
    errors,
    control,
    register,
    watch,
    token,
    setToken,
    getValues,
    needValidate,
    needValidateTime,
    watchIsPrivate,
    getTokenInforDetail,
    handlerDeployClaimPool,
    isDeployed,
    isEdit,
  } = props;

  const poolType = watchIsPrivate ? Number(watchIsPrivate) : 0;
  const poolForCommunity =
    poolType === POOL_IS_PRIVATE.COMMUNITY ||
    poolType === POOL_IS_PRIVATE.EVENT;
  const availableNetwork = watch("networkAvailable");
  const claimNetwork = watch("networkClaim");
  const displayPool = watch("is_display");
  const isSolanaPool = isDeployed
    ? availableNetwork === NETWORK_AVAILABLE.SOLANA
    : isEdit
    ? watch("supported_token_types") === NETWORK_AVAILABLE.SOLANA
    : watch("supported_token_types") === NETWORK_AVAILABLE.SOLANA;
  const duoNetworkPool = useMemo(() => {
    return availableNetwork !== claimNetwork;
  }, [availableNetwork, claimNetwork]);

  const [pageReload, setPageReload] = useState(false);

  const isEditShortDescription = UseCheckPermissions("EDIT_SHORT_DESCRIPTION");
  const isEditProduct = UseCheckPermissions("EDIT_PRODUCT");
  const isEditRoadmap = UseCheckPermissions("EDIT_ROADMAP");
  const isEditBusnessModal = UseCheckPermissions("EDIT_BUSINESS_MODEL");
  const isEditTeam = UseCheckPermissions("EDIT_TEAM");
  const isEditPartnersInvestor = UseCheckPermissions("EDIT_PARTNERS_INVESTER");
  const isEditTokenMetrics = UseCheckPermissions("EDIT_TOKEN_METRICS");

  const isEditProjectName = UseCheckPermissions("EDIT_PROJECT_NAME");
  const isEditPoolBanner = UseCheckPermissions("EDIT_POOL_BANNER");
  const isEditWebsite = UseCheckPermissions("EDIT_WEBSITE");
  const isEditRelationshipType = UseCheckPermissions("EDIT_RELATIONSHIP_TYPE");
  const isEditMarketMaker = UseCheckPermissions("EDIT_MARKET_MAKER");
  const isEditMarketMakerIcon = UseCheckPermissions("EDIT_MARKET_MAKER_ICON");
  const isEditRefundTerms = UseCheckPermissions("EDIT_REFUND_TERMS");
  const isEditTokenSymbol = UseCheckPermissions("EDIT_TOKEN_SYMBOL");
  const isEditTokenIcon = UseCheckPermissions("EDIT_TOKEN_ICON");
  const isEditCoingeckoId = UseCheckPermissions("EDIT_COINGECKO_ID");
  const isEditATHPrice = UseCheckPermissions("EDIT_ATH_PRICE");

  const isEditDisplayToggle = UseCheckPermissions("DISPLAY_TOGLE");
  const isEditLockPool = UseCheckPermissions("LOCK_POOL");
  const isEditKYCBypass = UseCheckPermissions("KYC_BYPASS");
  const isEditTotalTokenAmount = UseCheckPermissions("EDIT_TOTAL_TOKEN_AMOUNT");
  const isEditPoolNetwork = UseCheckPermissions("EDIT_POOL_NETWORK");
  const isEditTokenAddress = UseCheckPermissions("EDIT_TOKEN_ADDRESS");
  const isEditExchangeRate = UseCheckPermissions("EDIT_EXCHANGE_RATE");
  const isEditPricePerToken = UseCheckPermissions("EDIT_SHOW_PRICE_PER_TOKEN");
  const isForbiddenCountries = UseCheckPermissions("EDIT_FORBIDEN_COUNTRIES");
  const isEditReceiverAddress = UseCheckPermissions(
    "EDIT_RECEIVE_MONEY_ADDRESS"
  );

  const isDepositToken = UseCheckPermissions("DEPOSIT_TOKEN");
  const isDepositCurrency = UseCheckPermissions("DEPOSIT_CURRENCY");
  const isWithdrawRemainingToken = UseCheckPermissions(
    "WITHDRAW_REMAINING_TOKEN_AMOUNT"
  );
  const isWithdrawRefundAmount = UseCheckPermissions(
    "WITHDRAW_REMAINING_REFUND_AMOUNT"
  );
  const isDepositRefundAmount = UseCheckPermissions("DEPOSIT_REFUNDING_AMOUNT");

  const isEditPoolType = UseCheckPermissions("POOL_TYPE");
  const isEditTierVisibility = UseCheckPermissions("TIER_BASE_VISIBILITY");

  const isEditImcValue = UseCheckPermissions("EDIT_IMC_VALUE");
  const isEditTotalImcValue = UseCheckPermissions("EDIT_TOTAL_IMC_VALUE");
  const isEditFdvValue = UseCheckPermissions("EDIT_FDV_VALUE");

  const isEditTokenSoldDisplay = UseCheckPermissions("TOKEN_SOLD_DISPLAY");

  // const isGiveawayPool = false;
  const isGiveawayPool = poolDetail?.giveaway_version
    ? (watch("relationship_type") === "Giveaway"  || watch("relationship_type") === "Giveaway (Labs Incubation)") &&
      poolDetail?.giveaway_version === "giveaway_v2"
    : (watch("relationship_type") === "Giveaway" || watch("relationship_type") === "Giveaway (Labs Incubation)") &&
      GIVEAWAY_VERSION === "giveaway_v2";
  const isTonAddressVisible = watch("is_custom_token") === 1;
  const isClaimLinkVisible = watch("is_claim_link") === 1;

  return (
    <>
      <div style={{ display: showTab ? "inherit" : "none" }}>
        <Grid container spacing={2}>
          <Grid item xs={6} className={classes.exchangeRate}>
            {!!poolDetail?.id && (
              <DisplayPoolSwitch
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
                setPageReload={setPageReload}
                isEditDisabled={!isEditDisplayToggle}
              />
            )}
            {V2PoolsToggle && (
              <V2Pools
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
                isEditDisabled={!isEditLockPool}
              />
            )}
            {isScheduleTime && (
              <DisplayScheduleTime
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
                setPageReload={setPageReload}
                isEditDisabled={!isEditDisplayToggle}
              />
            )}

            {!!poolDetail?.id && !displayPool && !pageReload && (
              <LockPool
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
                isEditDisabled={!isEditLockPool}
              />
            )}

            {FEATURE_POOL && poolDetail.id && (
              <FeaturedPool
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
                isEditDisabled={!isEditLockPool}
              />
            )}

            {!isDeployed && SOLANA && (
              <PoolType
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                isEditDisabled={!isEditPoolType}
              />
            )}

            <UserTier
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              isEditDisabled={!isEditTierVisibility}
            />

            <PoolName
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              isEditDisabled={!isEditProjectName}
            />

            <PoolBanner
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              getValues={getValues}
              isEditDisabled={!isEditPoolBanner}
            />

            <PoolIcon
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              getValues={getValues}
              isEditDisabled={!isEditPoolBanner}
            />

            <PoolWebsite
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              isEditDisabled={!isEditWebsite}
            />

            <PoolRelationship
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              isEditDisabled={!isEditRelationshipType}
              isDeployed = {isDeployed}
            />

            <PoolMarketMaker
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              isEditDisabled={!isEditMarketMaker}
            />

            <PoolMarketMakerIcon
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              isEditDisabled={!isEditMarketMakerIcon}
            />

            <RefundTerms
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              isEditDisabled={!isEditRefundTerms}
            />

            <TokenSymbol
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              getTokenInforDetail={getTokenInforDetail}
              isEditDisabled={!isEditTokenSymbol}
            />

            <TokenLogo
              poolDetail={poolDetail}
              register={register}
              errors={errors}
              setValue={setValue}
              isEditDisabled={!isEditTokenIcon}
            />

            <TokenCoingeckoId
              poolDetail={poolDetail}
              register={register}
              errors={errors}
              isEditDisabled={!isEditCoingeckoId}
            />

            <TotalCoinSold
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
              isEditDisabled={!isEditTotalTokenAmount}
            />
            <InitialMarketCapitilization
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
              isEditDisabled={!isEditImcValue}
            />

            <TotalInitialMarketCapitilization
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
              isEditDisabled={!isEditTotalImcValue}
            />

            <FullyDelutedValuationComponent
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
              isEditDisabled={!isEditFdvValue}
            />

            {(watch("relationship_type") === "Giveaway" || watch("relationship_type") === "Giveaway (Labs Incubation)") && (
              <TokenPriceGiveaway
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
              />
            )}
            {/* <ATHPrice
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
          /> */}
            <StaticATHRoi
              poolDetail={poolDetail}
              register={register}
              errors={errors}
              isEditDisabled={!isEditATHPrice}
            />

            {!!poolDetail?.is_deploy && (
              <ProgressDisplaySetting
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                isEditDisabled={!isEditTokenSoldDisplay}
              />
            )}
          </Grid>
          <Grid item xs={6} className={classes.exchangeRate}>
            {isSolanaPool ? (
              <SolanaPoolHash
                poolDetail={poolDetail}
                duoNetworkPool={duoNetworkPool}
                watch={watch}
              />
            ) : (
              <PoolHash
                poolDetail={poolDetail}
                duoNetworkPool={duoNetworkPool}
                watch={watch}
              />
            )}
            {duoNetworkPool && !isSolanaPool && (
              <PoolClaimHash
                poolDetail={poolDetail}
                handlerDeployClaimPool={handlerDeployClaimPool}
              />
            )}

            <TokenAddress
              poolDetail={poolDetail}
              register={register}
              token={token}
              setToken={setToken}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              watch={watch}
              needValidate={needValidate}
              isEditDisabled={!isEditTokenAddress}
            />

            {isSolanaPool ? (
              <AddressReceiveMoney
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                needValidate={needValidate}
                watch={watch}
                isEditDisabled={!isEditReceiverAddress}
              />
            ) : (
              !isGiveawayPool && (
                <AddressReceiveMoney
                  poolDetail={poolDetail}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  needValidate={needValidate}
                  watch={watch}
                  isEditDisabled={!isEditReceiverAddress}
                />
              )
            )}

            {isSolanaPool ? (
              <ExchangeRate
                poolDetail={poolDetail}
                register={register}
                token={token}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
                needValidate={needValidate}
                isEditSDisabled={!isEditExchangeRate}
                isEditPricePerToken={!isEditPricePerToken}
              />
            ) : (
              !isGiveawayPool && (
                <ExchangeRate
                  poolDetail={poolDetail}
                  register={register}
                  token={token}
                  setValue={setValue}
                  errors={errors}
                  control={control}
                  watch={watch}
                  needValidate={needValidate}
                  isEditSDisabled={!isEditExchangeRate}
                  isEditPricePerToken={!isEditPricePerToken}
                />
              )
            )}
            {TRANSFER_OWNERSHIP && !isSolanaPool && (
              <TransferOwnership
                poolDetail={poolDetail}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
                token={token}
              />
            )}
            {TRANSFER_OWNERSHIP && isSolanaPool && (
              <SolanaTransferOwnership
                poolDetail={poolDetail}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
                token={token}
              />
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} className={classes.exchangeRate}>
            <BuyType
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
            />

            <PrivatePoolSetting
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
            />

            {/* <TokenType
            poolDetail={poolDetail}
            setValue={setValue}
            errors={errors}
            control={control}
          /> */}

            {isSolanaPool ? (
              <SolanaNetworkAvailable
                poolDetail={poolDetail}
                setValue={setValue}
                errors={errors}
                control={control}
                needValidate={needValidate}
                isEditDisabled={!isEditPoolNetwork}
              />
            ) : (
              <NetworkAvailable
                poolDetail={poolDetail}
                setValue={setValue}
                errors={errors}
                control={control}
                needValidate={needValidate}
                isEditDisabled={!isEditPoolNetwork}
                watch={watch}
              />
            )}
            {isSolanaPool ? (
              <SolanaClaimNetwork
                poolDetail={poolDetail}
                setValue={setValue}
                errors={errors}
                control={control}
                needValidate={needValidate}
                watch={watch}
              />
            ) : (
              <ClaimNetwork
                poolDetail={poolDetail}
                setValue={setValue}
                errors={errors}
                control={control}
                needValidate={needValidate}
                watch={watch}
              />
            )}
            {isSolanaPool ? (
              <SolanaAcceptCurrency
                poolDetail={poolDetail}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
                isEditDisabled={!isEditPoolNetwork}
              />
            ) : (
              !isGiveawayPool && (
                <AcceptCurrency
                  poolDetail={poolDetail}
                  setValue={setValue}
                  errors={errors}
                  control={control}
                  watch={watch}
                  isEditDisabled={!isEditPoolNetwork}
                />
              )
            )}

            <AirdropNetwork
              poolDetail={poolDetail}
              setValue={setValue}
              control={control}
            />

            <KycRequired
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
              isEditDisabled={!isEditKYCBypass}
            />

            <label className={classes.exchangeRateTitle}>
              Ton Network Setting
            </label>
            <br />
            <br />

            {TON_NETWORK && (
              <TonDistribution
                poolDetail={poolDetail}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
              />
            )}

            {TON_NETWORK && (
              <TonAddressToggle
                poolDetail={poolDetail}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
              />
            )}

            {isTonAddressVisible && (
              <TonInputBox
                poolDetail={poolDetail}
                register={register}
                token={token}
                setToken={setToken}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                watch={watch}
                needValidate={needValidate}
              />
            )}

            {TON_NETWORK && (
              <ClaimLink
                poolDetail={poolDetail}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
              />
            )}

            {isClaimLinkVisible && TON_NETWORK && (
              <ClaimLinkInput
                poolDetail={poolDetail}
                register={register}
                token={token}
                setToken={setToken}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                watch={watch}
                needValidate={needValidate}
              />
            )}
          </Grid>
          <Grid item xs={6} className={classes.exchangeRate}>
            <ForbiddenCountry
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
              isEditDisabled={!isForbiddenCountries}
            />

            {isDeployed &&
              availableNetwork &&
              availableNetwork !== undefined &&
              availableNetwork !== "tba" &&
              (availableNetwork === NETWORK_AVAILABLE.BSC ||
                availableNetwork === NETWORK_AVAILABLE.ETH ||
                availableNetwork === NETWORK_AVAILABLE.POLYGON ||
                availableNetwork === NETWORK_AVAILABLE.AVALANCHE ||
                availableNetwork === NETWORK_AVAILABLE.BASE ||
                availableNetwork === NETWORK_AVAILABLE.DAO ||
                availableNetwork === NETWORK_AVAILABLE.OKX ||
                availableNetwork === NETWORK_AVAILABLE.ZKSYNC ||
                availableNetwork === NETWORK_AVAILABLE.LINEA ||
                availableNetwork === NETWORK_AVAILABLE.BLAST ||
                availableNetwork === NETWORK_AVAILABLE.ARBITRUM) &&
              !isGiveawayPool && (
                <>
                  <DepositRefundBalance
                    poolDetail={poolDetail}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                    watch={watch}
                    permission={isDepositRefundAmount}
                  />
                  <WithdrawRefundBalance
                    poolDetail={poolDetail}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                    watch={watch}
                    permission={isWithdrawRefundAmount}
                  />
                  <WithdrawRemainingToken
                    poolDetail={poolDetail}
                    permission={isWithdrawRemainingToken}
                  />
                  {isDeployed &&
                    availableNetwork &&
                    availableNetwork !== undefined &&
                    availableNetwork !== "tba" &&
                    (availableNetwork === NETWORK_AVAILABLE.BSC ||
                      availableNetwork === NETWORK_AVAILABLE.ETH ||
                      availableNetwork === NETWORK_AVAILABLE.POLYGON ||
                      availableNetwork === NETWORK_AVAILABLE.AVALANCHE ||
                      availableNetwork === NETWORK_AVAILABLE.BASE ||
                      availableNetwork === NETWORK_AVAILABLE.DAO ||
                      availableNetwork === NETWORK_AVAILABLE.ZKSYNC ||
                      availableNetwork === NETWORK_AVAILABLE.OKX ||
                      availableNetwork === NETWORK_AVAILABLE.LINEA ||
                      availableNetwork === NETWORK_AVAILABLE.BLAST ||
                      availableNetwork === NETWORK_AVAILABLE.ARBITRUM) &&
                    availableNetwork !== NETWORK_AVAILABLE.SOLANA &&
                    !isGiveawayPool && (
                      <DepositToken
                        poolDetail={poolDetail}
                        setValue={setValue}
                        register={register}
                        watch={watch}
                        errors={errors}
                        control={control}
                        permission={isDepositToken}
                      />
                    )}

                  {isDeployed &&
                    availableNetwork &&
                    availableNetwork !== undefined &&
                    availableNetwork !== "tba" &&
                    (availableNetwork === NETWORK_AVAILABLE.BSC ||
                      availableNetwork === NETWORK_AVAILABLE.ETH ||
                      availableNetwork === NETWORK_AVAILABLE.POLYGON ||
                      availableNetwork === NETWORK_AVAILABLE.AVALANCHE ||
                      availableNetwork === NETWORK_AVAILABLE.BASE ||
                      availableNetwork === NETWORK_AVAILABLE.DAO ||
                      availableNetwork === NETWORK_AVAILABLE.ZKSYNC ||
                      availableNetwork === NETWORK_AVAILABLE.OKX ||
                      availableNetwork === NETWORK_AVAILABLE.LINEA ||
                      availableNetwork === NETWORK_AVAILABLE.BLAST ||
                      availableNetwork === NETWORK_AVAILABLE.ARBITRUM) &&
                    availableNetwork !== NETWORK_AVAILABLE.SOLANA &&
                    !isGiveawayPool && (
                      <DepositCurrency
                        poolDetail={poolDetail}
                        setValue={setValue}
                        register={register}
                        watch={watch}
                        errors={errors}
                        control={control}
                        permission={isDepositCurrency}
                      />
                    )}
                </>
              )}

            {isDeployed &&
              availableNetwork &&
              availableNetwork !== undefined &&
              availableNetwork !== "tba" &&
              (availableNetwork === NETWORK_AVAILABLE.BSC ||
                availableNetwork === NETWORK_AVAILABLE.ETH ||
                availableNetwork === NETWORK_AVAILABLE.POLYGON ||
                availableNetwork === NETWORK_AVAILABLE.LINEA ||
                availableNetwork === NETWORK_AVAILABLE.AVALANCHE ||
                availableNetwork === NETWORK_AVAILABLE.BASE ||
                availableNetwork === NETWORK_AVAILABLE.DAO ||
                availableNetwork === NETWORK_AVAILABLE.OKX ||
                availableNetwork === NETWORK_AVAILABLE.ZKSYNC ||
                availableNetwork === NETWORK_AVAILABLE.BLAST ||
                availableNetwork === NETWORK_AVAILABLE.ARBITRUM) &&
              isGiveawayPool && (
                <WithdrawRemainingToken
                  poolDetail={poolDetail}
                  permission={isWithdrawRemainingToken}
                />
              )}

            {isDeployed &&
              availableNetwork &&
              availableNetwork !== undefined &&
              availableNetwork !== "tba" &&
              (availableNetwork === NETWORK_AVAILABLE.BSC ||
                availableNetwork === NETWORK_AVAILABLE.ETH ||
                availableNetwork === NETWORK_AVAILABLE.POLYGON ||
                availableNetwork === NETWORK_AVAILABLE.AVALANCHE ||
                availableNetwork === NETWORK_AVAILABLE.BASE ||
                availableNetwork === NETWORK_AVAILABLE.DAO ||
                availableNetwork === NETWORK_AVAILABLE.ZKSYNC ||
                availableNetwork === NETWORK_AVAILABLE.LINEA ||
                availableNetwork === NETWORK_AVAILABLE.OKX ||
                availableNetwork === NETWORK_AVAILABLE.BLAST ||
                availableNetwork === NETWORK_AVAILABLE.ARBITRUM) &&
              availableNetwork !== NETWORK_AVAILABLE.SOLANA &&
              isGiveawayPool && (
                <DepositToken
                  poolDetail={poolDetail}
                  setValue={setValue}
                  register={register}
                  watch={watch}
                  errors={errors}
                  control={control}
                  permission={isDepositToken}
                />
              )}

            {isDeployed &&
              availableNetwork &&
              availableNetwork === NETWORK_AVAILABLE.SOLANA && (
                <>
                  {isDeployed && (
                    <SolanaDepositRefundBalance
                      poolDetail={poolDetail}
                      setValue={setValue}
                      errors={errors}
                      control={control}
                      watch={watch}
                      permission={isDepositRefundAmount}
                    />
                  )}
                  {isDeployed && (
                    <SolanaWithdrawRefundBalance
                      poolDetail={poolDetail}
                      setValue={setValue}
                      errors={errors}
                      control={control}
                      watch={watch}
                      permission={isWithdrawRefundAmount}
                    />
                  )}

                  {isDeployed && (
                    <SolanaWithdrawRemainingToken
                      poolDetail={poolDetail}
                      permission={isWithdrawRemainingToken}
                    />
                  )}
                  {!!poolDetail?.is_deploy && (
                    <SolanaDepositToken
                      poolDetail={poolDetail}
                      setValue={setValue}
                      register={register}
                      watch={watch}
                      errors={errors}
                      control={control}
                      permission={isDepositToken}
                    />
                  )}
                </>
              )}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} className={classes.exchangeRate}>
            <SocialSetting
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
              register={register}
            />

            {poolForCommunity && (
              <ParticipantNumber
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                control={control}
                watch={watch}
              />
            )}
          </Grid>
          <Grid item xs={6} className={classes.exchangeRate}>
            <GleamRequirement
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className=""></div>
          </Grid>

          <Grid item xs={6}></Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.exchangeRate}>
              <ShortDescription
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                isEditDisabled={!isEditShortDescription}
              />
              <PoolDescription
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                header="Product"
                fieldName="description"
                isEditDisabled={!isEditProduct}
              />
              <PoolDescription
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                header="Roadmap"
                fieldName="roadmap"
                isEditDisabled={!isEditRoadmap}
              />
              <PoolDescription
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                header="Business model"
                fieldName="business_model"
                isEditDisabled={!isEditBusnessModal}
              />
              <PoolDescription
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                header="Team"
                fieldName="team"
                isEditDisabled={!isEditTeam}
              />
              <PoolDescription
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                header="Partners and investors"
                fieldName="partners_investors"
                isEditDisabled={!isEditPartnersInvestor}
              />
              <PoolDescription
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                header="Token metrics"
                fieldName="token_metrics"
                isEditDisabled={!isEditTokenMetrics}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TabProjectInfo;
