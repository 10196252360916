import { apiRoute } from "../utils";
import { BaseRequest } from "./Request";
const queryString = require("query-string");

export const getFaq = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/faq/get-faqs`);
  url += "?" + queryString.stringify(queryParams);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const getFeeds = async () => {
  const baseRequest = new BaseRequest();
  let url = "/rss-feed";
  const response = (await baseRequest.get(url)) as any;
  console.log(response, "response");
  return await response?.json();
};

export const addBlogDetails = async (
  blogId: any,
  campaignId: any,
  category: any
) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/blog`);
  const response = (await baseRequest.post(url, {
    blog_id: blogId,
    campaign_id: campaignId,
    category,
  })) as any;
  return await response?.json();
};

export const updateBlog = async (
  blog: { blogId: any; campaignId: any },
  Id: number
) => {
  const baseRequest = new BaseRequest();
  let url = `/blog/${Id}`;
  const response = (await baseRequest.patch(url, blog)) as any;
  return await response?.json();
};

export const getBlogById = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/blog/${Id}`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const blogStatusUpdate = async (isActive: number, Id: number) => {
  const baseRequest = new BaseRequest();
  let url = `/blog/${Id}`;
  const response = (await baseRequest.patch(url, {
    is_active: isActive,
  })) as any;
  return await response?.json();
};

export const getSocialMediaLinks = async () => {
  const baseRequest = new BaseRequest();
  let url = "/admin/social-media-link";
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const getSocialMediaTypes = async () => {
  const baseRequest = new BaseRequest();
  let url = "/admin/social-media-type";
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const updateSocialMediaLinks = async (body: any, id: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/social-media-link/${id}`;
  const response = (await baseRequest.put(url, body)) as any;
  return await response?.json();
};

export const addSocialMediaLinks = async (body: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/social-media-link`;
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const deleteSocialMediaLinks = async (id: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/social-media-link/${id}`;
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};

export const updateSortingSocialMedia = async (body: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/social-media-link`;
  const response = (await baseRequest.patch(url, body)) as any;
  return await response?.json();
};

export const getNeedHelpFaq = async () => {
  const baseRequest = new BaseRequest();
  let url = `/admin/need-help/faq`;
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const addNeedHelpFaq = async (url: any, body: any, method: any) => {
  const baseRequest = new BaseRequest();
  const response =
    method === "put"
      ? ((await baseRequest.put(url, body)) as any)
      : ((await baseRequest.post(url, body)) as any);
  return await response?.json();
};

export const deleteNeedHelpFaq = async (id: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/need-help/faq/${id}`;
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};

export const needHelpFaqUpdateSorting = async (body: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/need-help/faq/sort`;
  const response = (await baseRequest.patch(url, body)) as any;
  return await response?.json();
};

export const getHelpEmail = async () => {
  const baseRequest = new BaseRequest();
  let url = `/admin/need-help/support-email`;
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const addHelpEmail = async (body: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/need-help/support-email`;
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const getGuideData = async () => {
  const baseRequest = new BaseRequest();
  let url = `/admin/need-help/guide`;
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const addGuide = async (url: any, body: any, method: any) => {
  const baseRequest = new BaseRequest();
  const response =
    method === "put"
      ? ((await baseRequest.put(url, body)) as any)
      : ((await baseRequest.post(url, body)) as any);
  return await response?.json();
};

export const deleteGuideData = async (url: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};

export const updateGuideSorting = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.patch(url, body)) as any;
  return await response?.json();
};

export const getThemeColors = async () => {
  const baseRequest = new BaseRequest();
  let url = `/admin/theme-option`;
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const addThemeColors = async (body: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/theme-option`;
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const addPrivacyPolicy = async (body: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/privacy-policy/submit`;
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const getPrivacyPolicy = async () => {
  const baseRequest = new BaseRequest();
  let url = `/admin/privacy-policy/get`;
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const addTermsAndServices = async (body: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/termsofservice`;
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const getTermsAndServices = async () => {
  const baseRequest = new BaseRequest();
  let url = `/admin/termsofservice`;
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const getTiers = async (url: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const updateTiers = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.put(url, body)) as any;
  return await response?.json();
};

export const updateFaq = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const addTiers = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const deleteTiers = async (id: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/need-help/faq/${id}`;
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};

export const updateTierSorting = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.patch(url, body)) as any;
  return await response?.json();
};

export const getTierBenefits = async (url: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const addTierBenefits = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const updateTiersBenefits = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.put(url, body)) as any;
  return await response?.json();
};

export const deleteTierBenefits = async (id: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/need-help/faq/${id}`;
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};

export const updateTierBenefitsSorting = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.patch(url, body)) as any;
  return await response?.json();
};

export const getTierOverview = async (url: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const addTierOverview = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const updateTiersOverview = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.put(url, body)) as any;
  return await response?.json();
};

export const deleteTierOverview = async (id: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/need-help/faq/${id}`;
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};

export const updateTierOverviewSorting = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.patch(url, body)) as any;
  return await response?.json();
};

export const getTierStaking = async (url: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const addTierStaking = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const updateTiersStaking = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.put(url, body)) as any;
  return await response?.json();
};

export const deleteTierStaking = async (url: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};

export const getMenu = async (url: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const addMenu = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const updateMenu = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.put(url, body)) as any;
  return await response?.json();
};

export const deleteMenu = async (id: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/need-help/faq/${id}`;
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};

export const updateMenuSorting = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.patch(url, body)) as any;
  return await response?.json();
};

export const getFaqs = async (url: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const getFaqsCategories = async (url: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const addFaqs = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const updateFaqs = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.put(url, body)) as any;
  return await response?.json();
};

export const deleteFaqs = async (id: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/need-help/faq/${id}`;
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};

export const updateFaqsSorting = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.patch(url, body)) as any;
  return await response?.json();
};

export const getLandingPageData = async (url: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const getRegisterPage = async (url: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const addRegisterPage = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const updateRegisterPage = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.put(url, body)) as any;
  return await response?.json();
};

export const deleteRegisterPage = async (id: any) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/need-help/faq/${id}`;
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};

export const updateRegisterPageSorting = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.patch(url, body)) as any;
  return await response?.json();
};


export const getFeaturePage = async (url: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const addFeaturePage = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const updateFeaturePage = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.put(url, body)) as any;
  return await response?.json();
};

export const deleteFeaturePage = async (url: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};

export const updateFeaturePageSorting = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.patch(url, body)) as any;
  return await response?.json();
};


export const getGoogleFormPage = async (url: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const addGoogleFormPage = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const postFile = async (url: any, body: any) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};