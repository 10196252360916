import React from 'react'
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import useStyles from "../../style";

const TableWinnerForm = (props: any) => {
    const classes = useStyles();
    const {
      isOpenEditPopup,
      setIsOpenEditPopup,
      handlePassword,
      setPassword,
      password,
    } = props;
  return (
    <>
      <ConfirmDialog
        title={"Enter Password"}
        open={isOpenEditPopup}
        onConfirm={handlePassword}
        onCancel={() => {
          setIsOpenEditPopup(false);
          setPassword("")
        }}
        confirmLoading={false}
      >
        <div className={classes.formControl}>
          <label className={classes.formControlLabel}>Password</label>
          <div>
            <input
              placeholder="Please enter password"
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              className={`${classes.formInputBox}`}
              style={{ width: "100%" }}
              type='password'
              autoComplete='off'
              // disabled={isDeployed}
            />
          </div>
        </div>
      </ConfirmDialog>
    </>
  );
}

export default TableWinnerForm