import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useStyles from "../../style";
import React from "react";
import SolanaWithdrawButton from "./SolanaWithdrawButton";
import useGetSolanaRefundBalance from "../hooks/useGetSolanaRefundBalance";
import { getPoolMetadata } from "../../../../utils";

function SolanaWithdrawRefundBalance(props: any) {
  const classes = useStyles();
  const { poolDetail, permission } = props;
  const { data: loginUser } = useSelector((state: any) => state.user);

  const [isOwner, setIsOwner] = useState<boolean>(false);

  const isDeployed = !!poolDetail?.is_deploy;

  const { currencyAddress, getRefundBalance, rawContractBalance } =
    useGetSolanaRefundBalance({
      poolDetail,
      connectedAccount: loginUser?.wallet_address,
    });

  const getOwner = async () => {

    const tx = await getPoolMetadata(poolDetail?.id);
    const owner = tx.owner;
    setIsOwner(owner === window?.solana?.publicKey?.toBase58());
  };
  useEffect(() => {
    if (poolDetail) {
      getRefundBalance();
      getOwner();
    }
  }, [poolDetail]);

  if (!isDeployed) return <></>;

  return (
    <>
      <div className={classes.formControl} style={{ marginTop: 20 }}>
        <SolanaWithdrawButton
          poolDetail={poolDetail}
          currencyAddress={currencyAddress}
          disabled={
            !isOwner ||
            !currencyAddress ||
            !rawContractBalance ||
            +rawContractBalance === 0 || !permission
          }
        />
      </div>
    </>
  );
}

export default SolanaWithdrawRefundBalance;
