import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import useStyles from "./style";
import CustomAccordion from "./CustomAccordion";
import InputField from "./TextField";
import CustomButton from "./Button";
import { apiRoute, convertToTransaction, SendSolanaTransaction } from "../../../utils";
import { BaseRequest } from "../../../request/Request";
import { Keypair } from "@solana/web3.js";

export default function ChangeSaleToken({
  vaultAddress,
  pool_index,
  poolId,
  multiSigWallet,
  isValidVault,
  owner,
  disableButton
}: any) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [tokenMint, setTokenMint] = useState("");
  const [errors, setErrors] = useState<any>("");
  const [loading, setLoading] = useState<any>(false);

  const handleTokenMintChange = (e: any) => {
    if (tokenMint) {
      setErrors((prevErrors: any) => {
        const { tokenMint, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
    setTokenMint(e.target.value);
  };

  useEffect(() => {
    if (tokenMint) {
      setErrors((prevErrors: any) => {
        const { tokenMint, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [tokenMint]);

  const changeSaleToken = async () => {
    if (!window?.solana?.publicKey) {
      dispatch(alertFailure("Please Connect Solana Wallet"));
      return;
    }
    if(!pool_index) {
      dispatch(alertFailure("Pool is not deployed"))
      return;
    }
    if (!multiSigWallet) {
      dispatch(alertFailure("Please Enter MultiSig Wallet Address"));
      return;
    }
    if (!vaultAddress) {
      dispatch(alertFailure("Please Enter Vault Address"));
      return;
    }
    if(owner && !isValidVault) {
      dispatch(alertFailure("Vault Address should be the owner of Pool"));
      return;
    }
    const errors: any = {};
    if (!tokenMint) {
      errors.tokenMint = "Token Mint is required";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      try {
        const squads_multi_sig_address = multiSigWallet;
        const baseRequest = new BaseRequest();
        var tokenAccountKeypair = Keypair.generate();
        let url = apiRoute(`/solana-multisig/change-sale-token`);
        const body = {
          squads_multi_sig_address: squads_multi_sig_address, 
          campaign_id: poolId, 
          token_mint: tokenMint,
          fee_payer: window?.solana?.publicKey.toString(),
          token_account_publickey: tokenAccountKeypair.publicKey
      }
        const response = (await baseRequest.post(url, body)) as any;
        const result = await response.json();
        const data = result.data;
        const tx = await convertToTransaction(data)
        // const sig = await SendSolanaTransaction(provider, tx, [tokenAccountKeypair]);
        const sig = await SendSolanaTransaction(tx, [tokenAccountKeypair]);
        dispatch(alertSuccess("Transaction Successfull"));
        setTokenMint("");
        setLoading(false);
      } catch (error) {
        dispatch(alertFailure("Transaction failed"));
        setTokenMint("");
        setLoading(false);
      }
    } else {
      setErrors(errors);
    }
  };
  return (
    <div className={classes.spaceAbove}>
      <div className={classes.columnFlex}>
        <CustomAccordion title="Change Sale Token">
          <div className={classes.columnFlex}>
            <InputField
              label="Enter Token Mint"
              id="tokenMint"
              value={tokenMint}
              onChange={handleTokenMintChange}
              error={errors.tokenMint}
              disableButton = {disableButton}
            />
            <CustomButton disableButton = {disableButton} loading={loading} onClick={() => changeSaleToken()} />
          </div>
        </CustomAccordion>
      </div>
    </div>
  );
}
