import { apiRoute } from "../utils";
import { BaseRequest } from "./Request";
const queryString = require('query-string');

export const getAdminLogs = async (queryParams: any) => {
    const baseRequest = new BaseRequest();
    let url = apiRoute(`/activity-history`);
    url += '?' + queryString.stringify(queryParams);
  
    const response = await baseRequest.get(url) as any;
    const resObject = await response.json();
  
    return resObject;
  };

  export const deleteAdminLogs = async (userId: any) => {
    const baseRequest = new BaseRequest();
    let url = apiRoute(`/activity-history/${userId}`);
  
    const response = await baseRequest.delete(url, {}) as any;
    const resObject = await response.json();
  
    return resObject;
  }