import React from "react";
import useStyles from "../style";
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useState, useEffect } from "react";
import { getContractInstance, getPoolContract } from "../../../services/web3";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { ACCEPT_NETWORKS, CHAIN_ID_NAME_MAPPING, POOL_TYPE } from "../../../constants";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import { useDispatch, useSelector } from "react-redux";
import campaignABI from "../../../../src/abi/Swap/Campaign.json";
import { MULTISIG_ADDRESSES, MULTISIG_ADDRESSES_ARRAY } from "../../../multisig-walletaddresses";
import { getWhiteListWallets } from "../../../request/white-list-wallets";
import TransferOwnershipForm from "./TransferOwnershipForm";
const TransferOwnership = (props: any) => {
  const classes = useStyles();
  const { poolDetail, currencyAddress, disabled, watch } = props;
  const { data: loginUser } = useTypedSelector((state: any) => state.user);
  const [loading, setLoading] = useState(false);
  const [transactionHash, setTransactionHash] = useState<any>();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [address, setAddress] = useState<any>("");
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const [whiteListWallets, setWhiteListWallets] = useState<any>([]);
  const [isOpenEditPopup, setIsOpenEditPopup] = useState<boolean>(false);
  const [displayOwner, setDisplayOwner] = useState("")
  const [error, setError] = useState("");
  const isDeployed = !!poolDetail?.is_deploy;
  const networkAvailable = watch("networkAvailable");
  const { userCurrentNetwork } = useSelector((state: any) => state);
  const currentNetworkId = userCurrentNetwork?.currentNetworkId;
  const [acceptedNetwork, setAcceptedNetwork] = useState<any>(false)

  useEffect(() => {
    if (currentNetworkId === "") {
      setAcceptedNetwork(false);
    } else if (Object.values(ACCEPT_NETWORKS).includes(currentNetworkId)) {
      setAcceptedNetwork(false);
    } else {
      setAcceptedNetwork(true);
    }
  }, [currentNetworkId]);
  console.log(currentNetworkId,acceptedNetwork, "CURRENCT NETWORK")

 

  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const response = await getWhiteListWallets();
      if (response?.status === 200) {
        console.log("success", response);
        setWhiteListWallets(response?.data);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const getOwner = async () => {
      if (!poolDetail) return;
      if (!poolDetail.campaign_hash) return;

      const campaignContract = getContractInstance(
        campaignABI,
        poolDetail.campaign_hash
      );
      if (!campaignContract) {
        console.error("Campaign contract instance is not available.");
        return;
      }

      try {
        const owner = await campaignContract.methods.owner().call();
        setDisplayOwner(owner)
        setIsOwner(owner === loginUser?.wallet_address);
      } catch (error) {
        console.error("Error while getting owner:", error);
      }
    };

    getOwner();
  }, [poolDetail, loginUser, networkAvailable, acceptedNetwork]);

  // useEffect(() => {
  //   const expectedAddress = MULTISIG_ADDRESSES[poolDetail.network_available];

  //   if (MULTISIG_ADDRESSES_ARRAY.includes(address) &&  address !== expectedAddress) {
  //     setMessage(
  //       "Be careful you trying to transfer ownership to a wallet which is not multi sig"
  //     );
  //     setLoading(false);
  //     setDisableButton(false);
  //   } else {
  //     setMessage("");
  //   }
  // }, [address]);

  if (!isDeployed) return <></>;

  const handleTransferOwnership = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Do you want transfer ownership?")) {
      return false;
    }

    try {
      setLoading(true);
      setDisableButton(true);
      let hash;

      const poolContract = getPoolContract({
        networkAvailable: poolDetail.network_available,
        poolHash: poolDetail.campaign_hash,
        isClaimable: poolDetail.pool_type == POOL_TYPE.CLAIMABLE,
        poolVersion: poolDetail?.pool_version,
      });
      if (!loginUser?.wallet_address) return;
      const owner = await poolContract?.methods.owner().call();
      if (loginUser?.wallet_address !== owner) return;

      hash = await poolContract?.methods.transferOwnership(address.wallet).send({
        from: owner,
      });

      setTransactionHash(hash.transactionHash);
      setIsOpenEditPopup(false)

      setLoading(false);
      dispatch(alertSuccess("Transfer Success !!!"));
      window.location.reload();
    } catch (e) {
      setLoading(false);
      setDisableButton(false);
      dispatch(alertFailure("Transfer Fail !!!"));
      setIsOpenEditPopup(false)
      return false;
    }
  };

  const handleWalletChange = (event: any) => {
    if (address === "") {
      setError("");
    } else if (whiteListWallets.includes(address)) {
      setError("");
    }
    else {
      setError("");
    }
    setAddress(event.target.value);
  };
  
  const confirmTransfer = () => {
        let error = "";
        if (address === "") {
          error = "Please select Wallet address";
        } 
        else if(address.wallet === displayOwner) {
          error = "Selected Wallet address is already pool owner.";
        }
        else {
          error = ""
        }
        console.log(error)
      if (!error) {
        setIsOpenEditPopup(true);
      } else {
        setError(error);
      }
  };


  return (
    <div>
      <div className={classes.formControl}>
        <TransferOwnershipForm
          isOpenEditPopup={isOpenEditPopup}
          onConfirmTransfer={handleTransferOwnership}
          address={address}
          setIsOpenEditPopup={setIsOpenEditPopup}
          poolDetail={poolDetail}
          loading={loading}
          disableButton={disableButton}
        />
        <label className={classes.formControlLabel}>Owner</label>
        <p>{displayOwner}</p>
        {acceptedNetwork && (
          <>
            <p style={{ color: "red" }}>
              Please change to correct network Current Network:{" "}
              {CHAIN_ID_NAME_MAPPING[currentNetworkId]} ({currentNetworkId})
            </p>
          </>
        )}
        <label className={classes.formControlLabel}>Transfer Ownership</label>
        <div className={classes.formControlInputLoading}>
          {/* <input
            type="text"
            name="transferAddress"
            maxLength={255}
            className={classes.formControlInput}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          /> */}
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel id="network-label">Select Wallet</InputLabel>
            <Select
              labelId="network-label"
              id="network"
              value={address}
              onChange={handleWalletChange}
              label="Wallet"
              error={!!error}
            >
              <MenuItem value=" " disabled>
                Select Wallet
              </MenuItem>
              {whiteListWallets
                .filter(
                  (wallet: any) =>
                    wallet.network === poolDetail.network_available
                )
                .map((value: any, index : number) => {
                  return (
                    <MenuItem key={index} value={value}>
                      {value.wallet}
                    </MenuItem>
                  );
                })}
            </Select>
            {error && <div style={{ color: "red" }}>{error}</div>}
          </FormControl>
        </div>
        {message && <div style={{ color: "red" }}>{message}</div>}
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "10px" }}
          onClick={confirmTransfer}
          disabled={!isOwner || disableButton || acceptedNetwork}
        >
          Transfer Ownership
          {/* {loading && (
            <CircularProgress
              size={25}
              style={{ marginLeft: 10, color: "white" }}
            />
          )} */}
        </Button>
        {acceptedNetwork && (
          <>
            <p style={{ color: "red" }}>
              Please change to correct network Current Network:{" "}
              {CHAIN_ID_NAME_MAPPING[currentNetworkId]} ({currentNetworkId})
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default TransferOwnership;
