import React, { useEffect } from "react";
import useStyles from "../style";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import { renderErrorCreatePool } from "../../../utils/validate";
import {
  BSC_NETWORK_ACCEPT_CHAINS,
  POLYGON_NETWORK_ACCEPT_CHAINS,
  AVALANCE_NETWORK_ACCEPT_CHAINS,
  NETWORK_AVAILABLE,
  ARBITRUM_NETWORK_ACCEPT_CHAINS,
  BASE_NETWORK_ACCEPT_CHAINS,
  BASE_CHAIN,
  DAO_CHAIN,
  DAO_NETWORK_ACCEPT_CHAINS,
  XLAYER_CHAIN,
  OKX_NETWORK_ACCEPT_CHAINS,
  ZKSYNC,
  ZKSYNC_NETWORK_ACCEPT_CHAINS,
  LINEA_NETWORK_ACCEPT_CHAINS,
  LINEA_CHAIN,
  GIVEAWAY_SUPPORTED_CHAINS,
  BLAST_NETWORK_ACCEPT_CHAINS,
  BLAST_CHAIN,
  GIVEAWAY_VERSION,
  ETH_NETWORK_ACCEPT_CHAINS,
} from "../../../constants";
import { useSelector } from "react-redux";

function NetworkAvailable(props: any) {
  const classes = useStyles();
  const {
    setValue,
    errors,
    control,
    poolDetail,
    needValidate,
    isEditDisabled,
    watch,
  } = props;
  const renderError = renderErrorCreatePool;
  const { currentNetworkId } = useSelector(
    (state: any) => state
  ).userCurrentNetwork;

  useEffect(() => {
    if (poolDetail && poolDetail.network_available) {
      setValue("networkAvailable", poolDetail.network_available);
    }
  }, [poolDetail]);
  const isDeployed = !!poolDetail?.is_deploy;

  const isGiveawayPool = poolDetail?.giveaway_version
    ? (watch("relationship_type") === "Giveaway" || watch("relationship_type") === "Giveaway (Labs Incubation)") &&
      poolDetail?.giveaway_version === "giveaway_v2"
    : (watch("relationship_type") === "Giveaway" || watch("relationship_type") === "Giveaway (Labs Incubation)") && GIVEAWAY_VERSION === "giveaway_v2";
  // const isGiveawayPool = false;

  return (
    <>
      <div className={classes.formControl}>
        <FormControl component="fieldset" disabled={isEditDisabled}>
          <label className={classes.formControlLabel}>Pool Network</label>

          <Controller
            rules={{
              required: true,
              validate: {
                networkNotMatch: (value) => {
                  // Validate Only click Deploy button
                  if (!needValidate) return true;
                  let acceptNet = "";
                  switch (value) {
                    case "eth":
                      acceptNet = ETH_NETWORK_ACCEPT_CHAINS[Number(currentNetworkId)];
                      break;
                    case "bsc":
                      acceptNet = BSC_NETWORK_ACCEPT_CHAINS[Number(currentNetworkId)];
                      break;
                    case "polygon":
                      acceptNet =
                        POLYGON_NETWORK_ACCEPT_CHAINS[Number(currentNetworkId)];
                      break;
                    case "avalanche":
                      acceptNet =
                        AVALANCE_NETWORK_ACCEPT_CHAINS[Number(currentNetworkId)];
                      break;
                    case "arbitrum":
                      acceptNet =
                        ARBITRUM_NETWORK_ACCEPT_CHAINS[Number(currentNetworkId)];
                      break;
                    case "base":
                      acceptNet = BASE_NETWORK_ACCEPT_CHAINS[Number(currentNetworkId)];
                      break;
                    case "coredao":
                      acceptNet = DAO_NETWORK_ACCEPT_CHAINS[Number(currentNetworkId)];
                      break;
                    case "xlayer":
                      acceptNet = OKX_NETWORK_ACCEPT_CHAINS[Number(currentNetworkId)];
                      break;
                    case "zksync":
                      acceptNet =
                        ZKSYNC_NETWORK_ACCEPT_CHAINS[Number(currentNetworkId)];
                      break;
                    case 'linea':
                      acceptNet = LINEA_NETWORK_ACCEPT_CHAINS[Number(currentNetworkId)]
                      break;
                    case 'blast':
                      acceptNet = BLAST_NETWORK_ACCEPT_CHAINS[Number(currentNetworkId)]
                      break;
                    default:
                      acceptNet = "tba";
                  }

                  console.log("acceptNet", acceptNet);
                  if (!acceptNet) {
                    console.log("Network Deploy not match!!!");
                    return false;
                  }
                  return true;
                },
              },
            }}
            control={control}
            defaultValue="tba"
            name="networkAvailable"
            as={
              <RadioGroup row>
                {!isGiveawayPool ? (
                  <FormControlLabel
                    value={NETWORK_AVAILABLE.ETH}
                    control={<Radio />}
                    label="Ether"
                    disabled={isDeployed || isEditDisabled}
                  />
                ) : (
                  GIVEAWAY_SUPPORTED_CHAINS[NETWORK_AVAILABLE.ETH] && (
                    <FormControlLabel
                      value={NETWORK_AVAILABLE.ETH}
                      control={<Radio />}
                      label="Ether"
                      disabled={isDeployed || isEditDisabled}
                    />
                  )
                )}
                {!isGiveawayPool ? (
                  <FormControlLabel
                    value={NETWORK_AVAILABLE.BSC}
                    control={<Radio />}
                    label="BSC"
                    disabled={isDeployed || isEditDisabled}
                  />
                ) : (
                  GIVEAWAY_SUPPORTED_CHAINS[NETWORK_AVAILABLE.BSC] && (
                    <FormControlLabel
                      value={NETWORK_AVAILABLE.BSC}
                      control={<Radio />}
                      label="BSC"
                      disabled={isDeployed || isEditDisabled}
                    />
                  )
                )}
                {!isGiveawayPool ? (
                  <FormControlLabel
                    value={NETWORK_AVAILABLE.POLYGON}
                    control={<Radio />}
                    label="Polygon"
                    disabled={isDeployed || isEditDisabled}
                  />
                ) : (
                  GIVEAWAY_SUPPORTED_CHAINS[NETWORK_AVAILABLE.POLYGON] && (
                    <FormControlLabel
                      value={NETWORK_AVAILABLE.POLYGON}
                      control={<Radio />}
                      label="Polygon"
                      disabled={isDeployed || isEditDisabled}
                    />
                  )
                )}
                {!isGiveawayPool ? (
                  <FormControlLabel
                    value={NETWORK_AVAILABLE.AVALANCHE}
                    control={<Radio />}
                    label="Avalanche"
                    disabled={isDeployed || isEditDisabled}
                  />
                ) : (
                  GIVEAWAY_SUPPORTED_CHAINS[NETWORK_AVAILABLE.AVALANCHE] && (
                    <FormControlLabel
                      value={NETWORK_AVAILABLE.AVALANCHE}
                      control={<Radio />}
                      label="Avalanche"
                      disabled={isDeployed || isEditDisabled}
                    />
                  )
                )}
                {!isGiveawayPool ? (
                  <FormControlLabel
                    value={NETWORK_AVAILABLE.ARBITRUM}
                    control={<Radio />}
                    label="Arbitrum"
                    disabled={isDeployed || isEditDisabled}
                  />
                ) : (
                  GIVEAWAY_SUPPORTED_CHAINS[NETWORK_AVAILABLE.ARBITRUM] && (
                    <FormControlLabel
                      value={NETWORK_AVAILABLE.ARBITRUM}
                      control={<Radio />}
                      label="Arbitrum"
                      disabled={isDeployed || isEditDisabled}
                    />
                  )
                )}
                {!isGiveawayPool
                  ? BASE_CHAIN && (
                      <FormControlLabel
                        value={NETWORK_AVAILABLE.BASE}
                        control={<Radio />}
                        label="Base"
                        disabled={isDeployed || isEditDisabled}
                      />
                    )
                  : GIVEAWAY_SUPPORTED_CHAINS[NETWORK_AVAILABLE.BASE] &&
                    BASE_CHAIN && (
                      <FormControlLabel
                        value={NETWORK_AVAILABLE.BASE}
                        control={<Radio />}
                        label="Base"
                        disabled={isDeployed || isEditDisabled}
                      />
                    )}
                {!isGiveawayPool
                  ? DAO_CHAIN && (
                      <FormControlLabel
                        value={NETWORK_AVAILABLE.DAO}
                        control={<Radio />}
                        label="Core Dao"
                        disabled={isDeployed || isEditDisabled}
                      />
                    )
                  : GIVEAWAY_SUPPORTED_CHAINS[NETWORK_AVAILABLE.DAO] &&
                    DAO_CHAIN && (
                      <FormControlLabel
                        value={NETWORK_AVAILABLE.DAO}
                        control={<Radio />}
                        label="Core Dao"
                        disabled={isDeployed || isEditDisabled}
                      />
                    )}
                {!isGiveawayPool
                  ? XLAYER_CHAIN && (
                      <FormControlLabel
                        value={NETWORK_AVAILABLE.OKX}
                        control={<Radio />}
                        label="X-Layer"
                        disabled={isDeployed || isEditDisabled}
                      />
                    )
                  : GIVEAWAY_SUPPORTED_CHAINS[NETWORK_AVAILABLE.OKX] &&
                    XLAYER_CHAIN && (
                      <FormControlLabel
                        value={NETWORK_AVAILABLE.OKX}
                        control={<Radio />}
                        label="X-Layer"
                        disabled={isDeployed || isEditDisabled}
                      />
                    )}

                {!isGiveawayPool
                  ? ZKSYNC && (
                      <FormControlLabel
                        value={NETWORK_AVAILABLE.ZKSYNC}
                        control={<Radio />}
                        label="zkSync"
                        disabled={isDeployed || isEditDisabled}
                      />
                    )
                  : ZKSYNC &&
                    GIVEAWAY_SUPPORTED_CHAINS[NETWORK_AVAILABLE.ZKSYNC] && (
                      <FormControlLabel
                        value={NETWORK_AVAILABLE.ZKSYNC}
                        control={<Radio />}
                        label="zkSync"
                        disabled={isDeployed || isEditDisabled}
                      />
                    )}

{!isGiveawayPool
                  ? LINEA_CHAIN && (
                      <FormControlLabel
                        value={NETWORK_AVAILABLE.LINEA}
                        control={<Radio />}
                        label="Linea"
                        disabled={isDeployed || isEditDisabled}
                      />
                    )
                  : LINEA_CHAIN &&
                    GIVEAWAY_SUPPORTED_CHAINS[NETWORK_AVAILABLE.LINEA] && (
                      <FormControlLabel
                        value={NETWORK_AVAILABLE.LINEA}
                        control={<Radio />}
                        label="Linea"
                        disabled={isDeployed || isEditDisabled}
                      />
                    )}
{!isGiveawayPool
                  ? BLAST_CHAIN && (
                      <FormControlLabel
                        value={NETWORK_AVAILABLE.BLAST}
                        control={<Radio />}
                        label="Blast"
                        disabled={isDeployed || isEditDisabled}
                      />
                    )
                  : BLAST_CHAIN &&
                    GIVEAWAY_SUPPORTED_CHAINS[NETWORK_AVAILABLE.BLAST] && (
                      <FormControlLabel
                        value={NETWORK_AVAILABLE.BLAST}
                        control={<Radio />}
                        label="Blast"
                        disabled={isDeployed || isEditDisabled}
                      />
                    )}
                <FormControlLabel
                  value={"tba"}
                  control={<Radio />}
                  label="TBA"
                  disabled={isDeployed || isEditDisabled}
                />
              </RadioGroup>
            }
          />
          <p className={classes.formErrorMessage}>
            {renderError(errors, "networkAvailable")}
            {renderError(errors, "networkNotMatch")}
          </p>
        </FormControl>
      </div>
    </>
  );
}

export default NetworkAvailable;
