import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import CustomAccordion from "./CustomAccordion";
import useStyles from "./style";
import InputField from "./TextField";
import CustomButton from "./Button";
import { apiRoute, convertToTransaction, SendSolanaTransaction } from "../../../utils";
import { BaseRequest } from "../../../request/Request";

export default function NewSigner({
  vaultAddress,
  pool_index,
  poolId,
  multiSigWallet,
  isValidVault,
  currentSigner,
  owner,
  disableButton
}: any) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [newSigner, setSigner] = useState(currentSigner);
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);

  useEffect(() => {
    setSigner(currentSigner)
  }, [currentSigner])

  const handleSignerChange = (e: any) => {
    if (newSigner) {
      setErrors((prevErrors: any) => {
        const { newSigner, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
    setSigner(e.target.value);
  };

  useEffect(() => {
    if (newSigner) {
      setErrors((prevErrors: any) => {
        const { newSigner, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [newSigner]);

  const setNewSigner = async () => {
    if (!window?.solana?.publicKey) {
      dispatch(alertFailure("Please Connect Solana Wallet"));
      return;
    }
    if(!pool_index) {
      dispatch(alertFailure("Pool is not deployed"))
      return;
    }
    if (!multiSigWallet) {
      dispatch(alertFailure("Please Enter MultiSig Wallet Address"));
      return;
    }
    if (!vaultAddress) {
      dispatch(alertFailure("Please Enter Vault Address"));
      return;
    }
    if(owner && !isValidVault) {
      dispatch(alertFailure("Vault Address should be the owner of Pool"));
      return;
    }
    if(newSigner === "11111111111111111111111111111111") {
      dispatch(alertFailure("Invalid Owner"));
      return;
    }
    if(currentSigner === newSigner) {
      dispatch(alertFailure("New signer cannot be the same as the old signer."));
      return;
    }
    const errors: any = {};
    if (!newSigner) {
      errors.newSigner = "Signer is Required";
    }
    if (Object.keys(errors).length === 0) {
      try {
        setLoading(true)
        const squads_multi_sig_address = multiSigWallet;
        const baseRequest = new BaseRequest();
        let url = apiRoute(`/solana-multisig/set-new-signer`);
        const body = {
          squads_multi_sig_address: squads_multi_sig_address, 
          campaign_id: poolId, 
          new_signer: newSigner,
          fee_payer: window?.solana?.publicKey.toString()
      }
        const response = (await baseRequest.post(url, body)) as any;
        const result = await response.json();
        const data = result.data;
        const tx = await convertToTransaction(data)
        const sig = await SendSolanaTransaction(tx);
        dispatch(alertSuccess("Transaction Successfull"));
        setSigner("");
        setLoading(false);
      } catch (error) {
        dispatch(alertFailure("Transaction failed"));
        setSigner("");
        setLoading(false);
      }
    } else {
      setErrors(errors);
    }
  };
  return (
    <div className={classes.spaceAbove}>
      <CustomAccordion title="Set New Signer">
        <div className={classes.columnFlex}>
          <InputField
            label="Enter Signer Wallet"
            id="newSigner"
            value={newSigner}
            onChange={handleSignerChange}
            error={errors.newSigner}
            disableButton = {disableButton}
          />
          <CustomButton disableButton = {disableButton} loading = {loading} onClick={() => setNewSigner()} />
        </div>
      </CustomAccordion>
    </div>
  );
}
