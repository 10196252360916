const BUY_TOKEN_LOADING = 'BUY_TOKEN_LOADING';
const BUY_TOKEN_SUCCESS = 'BUY_TOKEN_SUCCESS';
const BUY_TOKEN_FAILURE = 'BUY_TOKEN_FAILURE';

const BUY_TOKEN_AVAILABLE_LOADING = 'BUY_TOKEN_AVAILABLE_LOADING';
const BUY_TOKEN_AVAILABLE_SUCCESS = 'BUY_TOKEN_AVAILABLE_SUCCESS';
const BUY_TOKEN_AVAILABLE_FAILURE = 'BUY_TOKEN_AVAILABLE_FAILURE';


const CLAIM_TOKEN_LOADING = 'CLAIM_TOKEN_LOADING';
const CLAIM_TOKEN_SUCCESS = 'CLAIM_TOKEN_SUCCESS';
const CLAIM_TOKEN_FAILURE = 'CLAIM_TOKEN_FAILURE';

export const buyTokenActions = {
  BUY_TOKEN_LOADING,
  BUY_TOKEN_SUCCESS,
  BUY_TOKEN_FAILURE,
  BUY_TOKEN_AVAILABLE_LOADING,
  BUY_TOKEN_AVAILABLE_SUCCESS,
  BUY_TOKEN_AVAILABLE_FAILURE,
  CLAIM_TOKEN_LOADING,
  CLAIM_TOKEN_SUCCESS,
  CLAIM_TOKEN_FAILURE
};
