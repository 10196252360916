import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import useStyles from "./style";
import CustomAccordion from "./CustomAccordion";
import InputField from "./TextField";
import CustomButton from "./Button";
import { apiRoute, convertToTransaction, SendSolanaTransaction } from "../../../utils";
import { BaseRequest } from "../../../request/Request";

export default function TransferOwnership({
  vaultAddress,
  pool_index,
  poolId,
  multiSigWallet,
  isValidVault,
  currentOwner,
  disableButton
}: any) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [owner, setOwner] = useState(currentOwner);
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);

  useEffect(() => {
    setOwner(currentOwner);
  }, [currentOwner]);

  const handleOwnerChange = (e: any) => {
    if (owner) {
      setErrors((prevErrors: any) => {
        const { owner, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
    setOwner(e.target.value);
  };

  useEffect(() => {
    if (owner) {
      setErrors((prevErrors: any) => {
        const { owner, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [owner]);

  const transferPoolOwnerShip = async () => {
    if (!window?.solana?.publicKey) {
      dispatch(alertFailure("Please Connect Solana Wallet"));
      return;
    }
    if(!pool_index) {
      dispatch(alertFailure("Pool is not deployed"));
      return;
    }
    if (!multiSigWallet) {
      dispatch(alertFailure("Please Enter MultiSig Wallet Address"));
      return;
    }
    if (!vaultAddress) {
      dispatch(alertFailure("Please Enter Vault Address"));
      return;
    }
    if (currentOwner && !isValidVault) {
      dispatch(alertFailure("Vault Address should be the owner of Pool"));
      return;
    }
    if (owner === "11111111111111111111111111111111") {
      dispatch(alertFailure("Invalid Owner"));
      return;
    }
    if (currentOwner === owner) {
      dispatch(alertFailure("Owner Can't be same"));
      return;
    }
    const errors: any = {};
    if (!owner) {
      errors.owner = "Owner is Required";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true)
      try {
        const squads_multi_sig_address = multiSigWallet;
        const baseRequest = new BaseRequest();
        let url = apiRoute(`/solana-multisig/transfer-ownership`);
        const body = {
          squads_multi_sig_address: squads_multi_sig_address, 
          campaign_id: poolId, 
          new_owner: owner,
          fee_payer: window?.solana?.publicKey.toString()
      }
        const response = (await baseRequest.post(url, body)) as any;
        const result = await response.json();
        const data = result.data;
        const tx = await convertToTransaction(data)
        const sig = await SendSolanaTransaction(tx);
        dispatch(alertSuccess("Transaction Successfull"));
        setOwner("");
        setLoading(false);
      } catch (error) {
        dispatch(alertFailure("Transaction failed"));
        setOwner("");
        setLoading(false);
      }
    } else {
      setErrors(errors);
    }
  };

  return (
    <div className={classes.spaceAbove}>
      <CustomAccordion title="Transfer Pool Ownership">
        <div className={classes.columnFlex}>
          <InputField
            label="Enter Owner Address"
            id="ownerAddress"
            value={owner}
            onChange={handleOwnerChange}
            error={errors.owner}
            disableButton = {disableButton}
          />
          <CustomButton
            loading={loading}
            disableButton = {disableButton}
            onClick={() => transferPoolOwnerShip()}
          />
        </div>
      </CustomAccordion>
    </div>
  );
}
