import { JOB_ID } from "../constants";
import { apiRoute } from "../utils";
import { BaseRequest } from "./Request";

export const getAllTier = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/tier`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const calculateTierJob = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/calculate-tier-job`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const getAllFlags = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/flags`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const updateTier = async (
  tiers: { points: any; fcfs_multiplier: any; tier_base_multiplier: any; },
  Id: number
) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/tier/${Id}`);
  const response = (await baseRequest.patch(url, tiers)) as any;
  return await response?.json();
};

export const updateTierStatus = async (
  tiers: { tier: any; value: boolean },
  Id: number
) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/flags/${Id}`);
  const response = (await baseRequest.patch(url, tiers)) as any;
  return await response?.json();
};

export const getTierProgress = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/job/progress?jobId=${JOB_ID.progressBar}`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};