import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import useStyles from "../styles";
import { useCommonStyle } from "../../../../styles";
import {
  createAdmin,
  getAdminPermission,
  updateAdmin,
} from "../../../../request/admin";
import { alertFailure, alertSuccess } from "../../../../store/actions/alert";
import { useDispatch } from "react-redux";
import { adminRoute } from "../../../../utils";
import { PERMISSIONS } from "../../../../constants";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import {
  Group,
  Permission,
  PermissionsData,
} from "../../utils/PermissionTypes";
import { GroupName, PermissionKey } from "../../utils/PermissionEnums";
import {
  dependenciesMap,
  findPermissionInOtherGroup,
  getAllPermissionKeys,
  getPermissionLabel,
  organizePermissions,
} from "../../utils";
import Permissions from "./Permissions";

const FormAdmin = (props: any) => {
  const styles = useStyles();
  const commonStyle = useCommonStyle();
  const dispatch = useDispatch();

  const { history, isCreate } = props;
  let {
    id,
    username,
    email,
    firstname,
    lastname,
    wallet_address,
    is_active,
    permissions,
  } = props.admin;
  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      username,
      email,
      firstname,
      lastname,
      wallet_address,
      is_active,
    },
  });

  const { data: loginUser } = useTypedSelector((state) => state.user);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [checkedGroups, setCheckedGroups] = useState<{
    [key: string]: boolean;
  }>({});
  const [allPermissions, setPermissions] = useState<PermissionsData>({
    groups: [],
    singlePermissions: [],
  });
  const [checkedPermissions, setCheckedPermissions] = useState<{
    [key: string]: boolean;
  }>({});
  const [disabledPermissions, setDisabledPermissions] = useState<{ [key: string]: boolean }>({});
  const [disabledGroups, setDisabledGroups] = useState<{ [key: string]: boolean }>({});
  const [permissionErrors, setPermissionErrors] = useState<{ [key: string]: string }>({});
  const [groupErrors, setGroupErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchAdminPermissions = async () => {
      const response = await getAdminPermission();
      const organizedPermissions = organizePermissions(response?.data);
      setPermissions(organizedPermissions);
      const allPermissionsKeys = getAllPermissionKeys(organizedPermissions);
      const initialCheckedPermissions = allPermissionsKeys.reduce(
        (acc, key) => {
          acc[key] =
            permissions?.length > 0
              ? permissions?.some((perm: any) => perm.name === key)
              : false;
          return acc;
        },
        {} as { [key: string]: boolean }
      );
  
      const newCheckedPermissions = { ...initialCheckedPermissions };
      const newDisabledPermissions = { ...disabledPermissions };
      const newPermissionErrors = { ...permissionErrors };
  
      const applyDependencies = (permissionKey: PermissionKey) => {
        const dependencies = dependenciesMap[permissionKey];
        if (dependencies) {
          dependencies.forEach((dep: PermissionKey) => {
            newCheckedPermissions[dep] = true;
            newDisabledPermissions[dep] = true;
            newPermissionErrors[dep] = `${getPermissionLabel(dep)} is required by ${getPermissionLabel(permissionKey)}`;
            applyDependencies(dep);
          });
        }
      };
  
      allPermissionsKeys.forEach((key) => {
        if (newCheckedPermissions[key]) {
          applyDependencies(key);
        }
      });
  
      const initialCheckedGroups = organizedPermissions.groups.reduce(
        (acc, group) => {
          const allPermissionsInGroupChecked = group.permissions.every(
            (permission) => newCheckedPermissions[permission.name]
          );
          acc[group.name] = allPermissionsInGroupChecked;
          return acc;
        },
        {} as { [key: string]: boolean }
      );
  
      const newCheckedGroups = { ...initialCheckedGroups };
      const newDisabledGroups = { ...disabledGroups };
      const newGroupErrors = { ...groupErrors };
  
      allPermissions.groups.forEach((group) => {
        const allPermissionsInGroupDisabled = group.permissions.every(
          (permission) => newDisabledPermissions[permission.name]
        );
        newDisabledGroups[group.name] = allPermissionsInGroupDisabled;
        newGroupErrors[group.name] = allPermissionsInGroupDisabled ? 'All permissions in this group are disabled.' : '';
      });
  
      if (!newCheckedPermissions['CREATE_POOL'] && !newCheckedPermissions['LOGISTICS']) {
        newCheckedPermissions['EDIT_TOTAL_TOKEN_AMOUNT'] = false;
      }
  
      const anyGroupChecked = Object.values(newCheckedGroups).some((checked) => checked);
      if (anyGroupChecked) {
        newDisabledPermissions['LIST_POOL'] = true;
        newDisabledPermissions['UPDATE_POOL'] = true;
      }
  
      const allPermissionsChecked = Object.values(newCheckedPermissions).every(
        (value) => value
      );
      setIsSuperAdmin(allPermissionsChecked);
  
      setCheckedPermissions(newCheckedPermissions);
      setDisabledPermissions(newDisabledPermissions);
      setPermissionErrors(newPermissionErrors);
      setCheckedGroups(newCheckedGroups);
      setDisabledGroups(newDisabledGroups);
      setGroupErrors(newGroupErrors);
    };
    fetchAdminPermissions();
  }, [permissions]);

  const handlePermissionChange = (key: PermissionKey, checked: boolean) => {
    const newCheckedPermissions = { ...checkedPermissions, [key]: checked };
    const newDisabledPermissions = { ...disabledPermissions };
    const newPermissionErrors = { ...permissionErrors };

    const applyDependencies = (permissionKey: PermissionKey) => {
      const dependencies = dependenciesMap[permissionKey];
      if (dependencies) {
        dependencies.forEach((dep: PermissionKey) => {
          newCheckedPermissions[dep] = true;
          newDisabledPermissions[dep] = true;
          newPermissionErrors[dep] = `${getPermissionLabel(dep)} is required by ${getPermissionLabel(permissionKey)}`;
          applyDependencies(dep);
        });
      }
    };

    if (checked) {
      applyDependencies(key);
    } else {
      const removeDisabledDependencies = (permissionKey: PermissionKey) => {
        const dependencies = dependenciesMap[permissionKey];
        if (dependencies) {
          dependencies.forEach((dep: PermissionKey) => {
            newDisabledPermissions[dep] = false;
            delete newPermissionErrors[dep];
            removeDisabledDependencies(dep);
          });
        }
      };
      removeDisabledDependencies(key);
    }

    if (!newCheckedPermissions['CREATE_POOL'] && !newCheckedPermissions['LOGISTICS']) {
      newCheckedPermissions['EDIT_TOTAL_TOKEN_AMOUNT'] = false;
    } 

    setCheckedPermissions(newCheckedPermissions);
    setDisabledPermissions(newDisabledPermissions);
    setPermissionErrors(newPermissionErrors);

    const newCheckedGroups = { ...checkedGroups };
    const newDisabledGroups = { ...disabledGroups };
    const newGroupErrors = { ...groupErrors };

    allPermissions.groups.forEach((group) => {
      const allPermissionsInGroupChecked = group.permissions.every(
        (permission) => newCheckedPermissions[permission.name]
      );
      newCheckedGroups[group.name] = allPermissionsInGroupChecked;
      const allPermissionsInGroupDisabled = group.permissions.every(
        (permission) => newDisabledPermissions[permission.name]
      );
      newDisabledGroups[group.name] = allPermissionsInGroupDisabled;
      newGroupErrors[group.name] = allPermissionsInGroupDisabled ? 'All permissions in this group are disabled.' : '';
    });

    setCheckedGroups(newCheckedGroups);
    setDisabledGroups(newDisabledGroups);
    setGroupErrors(newGroupErrors);

    const allPermissionsChecked = Object.values(newCheckedPermissions).every(
      (value) => value
    );
    setIsSuperAdmin(allPermissionsChecked);
  };

  const handleGroupChange = (group: Group, checked: boolean) => {
    const newCheckedPermissions = { ...checkedPermissions };
    const newCheckedGroups: {
      [key: string]: boolean;
    } = { ...checkedGroups, [group.name]: checked };
    const newDisabledPermissions = { ...disabledPermissions };
    const newDisabledGroups = { ...disabledGroups };
    const newGroupErrors = { ...groupErrors };


    const group1 = allPermissions.groups.find((g) => g.name === group.name);
    if (group1) {
      group1.permissions.forEach((permission) => {
        if (checked) {
          newCheckedPermissions[permission.name] = checked;
          const dependencies = dependenciesMap[permission.name];
          if (dependencies) {
            dependencies.forEach((dep: PermissionKey) => {
              newCheckedPermissions[dep] = true;
              newDisabledPermissions[dep] = true;
            });
          }
        } else {
          const duplicatePermission = findPermissionInOtherGroup(
            permission.name,
            group1.name as GroupName,
            checkedGroups,
            allPermissions
          );
          if (duplicatePermission.length <= 0) {
            newCheckedPermissions[permission.name] = checked;
            const dependencies = dependenciesMap[permission.name];
            if (dependencies) {
              dependencies.forEach((dep: PermissionKey) => {
                newDisabledPermissions[dep] = false;
              });
            }
          }
        }
      });

      if (checked) {
        newCheckedPermissions['LIST_POOL'] = true;
        newCheckedPermissions['UPDATE_POOL'] = true;
        newDisabledPermissions['LIST_POOL'] = true;
        newDisabledPermissions['UPDATE_POOL'] = true;
      }
      else {
        newDisabledPermissions['UPDATE_POOL'] = false;
      }
    }

    if (!newCheckedPermissions['CREATE_POOL'] && !newCheckedPermissions['LOGISTICS']) {
      newCheckedPermissions['EDIT_TOTAL_TOKEN_AMOUNT'] = false;
    }

    allPermissions.groups.forEach((group) => {
      const allPermissionsInGroupDisabled = group.permissions.every(
        (permission) => newDisabledPermissions[permission.name]
      );
      newDisabledGroups[group.name] = allPermissionsInGroupDisabled;
      newGroupErrors[group.name] = allPermissionsInGroupDisabled ? 'All permissions in this group are disabled.' : '';
    });
    
    setCheckedPermissions(newCheckedPermissions);
    setCheckedGroups(newCheckedGroups);
    setDisabledPermissions(newDisabledPermissions);
    setDisabledGroups(newDisabledGroups);
    setGroupErrors(newGroupErrors);

    const allPermissionsChecked = Object.values(newCheckedPermissions).every(
      (value) => value
    );
    setIsSuperAdmin(allPermissionsChecked);
  };

  const handleSuperAdminToggle = (checked: boolean) => {
    const allPermissionsData: {
      [key: string]: boolean;
    } = {};
    const allGroupsData: { [key: string]: boolean } = {};
    const newDisabledPermissions: { [key: string]: boolean } = {};
    const newDisabledGroups: { [key: string]: boolean } = {};

    allPermissions.groups.forEach((group: Group) => {
      group.permissions.forEach((permission: Permission) => {
        allPermissionsData[permission.name] = checked;
        newDisabledPermissions[permission.name] = false;
      });
      allGroupsData[group.name] = checked;
      newDisabledGroups[group.name] = false;
    });
    allPermissions.singlePermissions.forEach((permission: Permission) => {
      allPermissionsData[permission.name] = checked;
      newDisabledPermissions[permission.name] = false;
    });
    setDisabledPermissions(newDisabledPermissions);
    setDisabledGroups(newDisabledGroups);
    setPermissionErrors({});
    setGroupErrors({});
    setCheckedPermissions(allPermissionsData);
    setCheckedGroups(allGroupsData);
    setIsSuperAdmin(checked);
  };

  const onSubmit = (data: any, event: any) => {
    event.preventDefault();
    const role = isSuperAdmin ? "SUPERADMIN" : "ADMIN";
    const selectedPermissionIds = Object.keys(checkedPermissions)
      .filter((key) => checkedPermissions[key as PermissionKey])
      .map((key) => {
        const permission = allPermissions.groups
          .flatMap((group: Group) => group.permissions)
          .concat(allPermissions.singlePermissions)
          .find((p: any) => p.name === key);
        return permission ? permission.id : null;
      })
      .filter((id) => id !== null);
    const formData: any = {
      username: data.username,
      email: data.email,
      wallet_address: data.wallet_address,
      firstname: data.firstname,
      lastname: data.lastname,
    };

    if (PERMISSIONS) {
      formData.permissionIds = selectedPermissionIds;
      formData.role = role;
    }
    if (isCreate) {
      createAdmin(formData).then((res) => {
        if (res.status === 200) {
          dispatch(alertSuccess("Create success"));
          history.push(adminRoute("/admins"));
        } else {
          dispatch(alertFailure(res.message || "Something went wrong"));
        }
      });
    } else {
      updateAdmin(id, formData).then((res) => {
        if (res.status === 200) {
          dispatch(alertSuccess("Update success"));
          history.push(adminRoute("/admins"));
        } else {
          dispatch(alertFailure(res.message || "Something went wrong"));
        }
      });
    }
  };

  useEffect(() => {
    setIsSuperAdmin(
      Object.values(checkedPermissions).length > 0 &&
        Object.values(checkedPermissions).every((value) => value)
    );
  }, [checkedPermissions]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={styles.groupInput}
          style={{
            marginTop: "20px",
          }}
        >
          {PERMISSIONS && (
            <Permissions
              adminId={props.admin.id}
              allPermissions={allPermissions}
              checkedPermissions={checkedPermissions}
              handleGroupChange={handleGroupChange}
              handlePermissionChange={handlePermissionChange}
              handleSuperAdminToggle={handleSuperAdminToggle}
              loginUser={loginUser}
              isSuperAdmin={isSuperAdmin}
              checkedGroup={checkedGroups}
              disabledPermissions = {disabledPermissions}
              disabledGroups = {disabledGroups}
              permissionErrors={permissionErrors}
              groupErrors = {groupErrors}
            />
          )}
          <label className={styles.inputLabel}>
            Username <span className={commonStyle.required}>*</span>
          </label>
          <input
            className={styles.inputG}
            name="username"
            placeholder=""
            ref={register({ required: true, maxLength: 255 })}
          />
          {errors.username && (
            <span className={commonStyle.error}>This field is required</span>
          )}
        </div>
        <div className="clearfix"></div>

        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>
            Email
            <span className={commonStyle.required}>*</span>
          </label>
          <input
            className={styles.inputG}
            name="email"
            placeholder="Email"
            ref={register({
              required: true,
              maxLength: 255,
            })}
          />
          {errors.email && (
            <span className={commonStyle.error}>This field is required</span>
          )}
        </div>
        <div className="clearfix"></div>

        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>
            Wallet address
            <span className={commonStyle.required}>*</span>
          </label>
          <input
            className={styles.inputG}
            name="wallet_address"
            placeholder=""
            ref={register({ required: true, maxLength: 255 })}
          />
          {errors.wallet_address && (
            <span className={commonStyle.error}>This field is required</span>
          )}
        </div>
        <div className="clearfix"></div>

        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>First Name</label>
          <input
            className={styles.inputG}
            name="firstname"
            placeholder=""
            ref={register({ maxLength: 255 })}
          />
          {errors.firstname && (
            <span className={commonStyle.error}>Max length is 255</span>
          )}
        </div>
        <div className="clearfix"></div>

        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>Last Name</label>
          <input
            className={styles.inputG}
            name="lastname"
            placeholder=""
            ref={register({ maxLength: 255 })}
          />
          {errors.lastname && (
            <span className={commonStyle.error}>Max length is 255</span>
          )}
        </div>
        <div className="clearfix"></div>

        <div className={styles.listBtn}>
          <Button type="submit" className={styles.btnSubmit}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default withRouter(FormAdmin);
