import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
interface Props {
  title: string;
  deleteModalOpen: boolean;
  setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDeleteClick: any;
}

const DeleteModal = ({
  title,
  deleteModalOpen,
  setDeleteModalOpen,
  onDeleteClick,
}: Props) => {
  return (
    <Modal isOpen={deleteModalOpen} size="sm">
      <ModalBody>
        <div className="mb-3">
          <p>Do you want to delete this {title}?</p>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            color="secondary"
            className="me-3 mr-3"
            onClick={() => setDeleteModalOpen(false)}
          >
            Cancel
          </Button>
          <Button color="danger" onClick={onDeleteClick}>
            Delete
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteModal;
