import { useCallback, useState } from "react";
import BigNumber from "bignumber.js";
import { PublicKey } from "@solana/web3.js";
import { getPoolCount, getRefundCurrency, getTokenInfo } from "../../../../utils";

const useSolanaRefundBalance = (props: any) => {
  const { poolDetail } = props;
  const [refundBalance, setRefundBalance] = useState<any>();
  const [rawRefundBalance, setRawRefundBalance] = useState<any>();
  const [currencyAddress, setCurrencyAddress] = useState<any>();
  const [contractBalance, setContractBalance] = useState<any>();
  const [rawContractBalance, setRawContractBalance] = useState<any>();
  const [depositBalance, setDepositBalance] = useState<any>();
  const [rawDepositBalance, setRawDepositBalance] = useState<any>();
  const [totalRefundToken, setTotalRefundToken] = useState<any>();

  const getRefundBalance = useCallback(async () => {
    try {
      const token_account_address = poolDetail?.refundable_token_account;

      var normalRefunded: any = 0;
      var totalRefunded: any = 0;
      try {
        const tx = await getRefundCurrency(
          poolDetail.id
        );
        normalRefunded = parseInt(tx.normal, 16);
        totalRefunded = parseInt(tx.total, 16);
      } catch (error) {
        console.log("🚀 ~ getRefundBalance ~ error:", error)
      }

      const account = await getTokenInfo(
        new PublicKey(token_account_address),
      );
      const rawContractBalance = new BigNumber(
        account.amount.toString()
      ).toFixed();

      const rawRefundBalance = normalRefunded.toString();
      setRefundBalance(
        new BigNumber(normalRefunded.toString())
          .div(new BigNumber(10).pow(6))
          .toFixed()
      );
      setRawRefundBalance(rawRefundBalance);
      setCurrencyAddress(currencyAddress);

      // const rawContractBalance = currencyAddress == NATIVE_TOKEN_ADDRESS ? new BigNumber(contractBalance).times(new BigNumber(10).pow(currencyDecimal)).toFixed() : new BigNumber(contractBalance).toFixed()

      let rawDepositBalance: any = new BigNumber(rawRefundBalance).minus(
        new BigNumber(rawContractBalance)
      );
      rawDepositBalance = rawDepositBalance.lte(new BigNumber(0))
        ? "0"
        : rawDepositBalance.toFixed();

      setRawContractBalance(rawContractBalance);
      setContractBalance(
        new BigNumber(rawContractBalance)
          .div(new BigNumber(10).pow(6))
          .toFixed()
      );

      setDepositBalance(
        new BigNumber(rawDepositBalance).div(new BigNumber(10).pow(6)).toFixed()
      );
      setRawDepositBalance(rawDepositBalance);
      setTotalRefundToken(
        new BigNumber(totalRefunded.toString())
          .div(new BigNumber(10).pow(6))
          .toFixed()
      );

      // console.log({depositBalance})
    } catch (error: any) {
      console.log("error: ", error.message);
    }
  }, [poolDetail]);

  return {
    refundBalance,
    rawRefundBalance,
    contractBalance,
    rawDepositBalance,
    depositBalance,
    rawContractBalance,
    currencyAddress,
    totalRefundToken,
    getRefundBalance,
  };
};

export default useSolanaRefundBalance;
